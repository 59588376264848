import styled, { css } from "styled-components";
import { gridGutters, gridSquares } from "../../styles/grid";
import {
    mountFlickerAnimationKeyframes,
    MOUNT_FLICKER_ANIMATION_DURATION_SECONDS,
} from "../Animations/MountFlickerAnimation";

export const ScreenLayout = styled.section`
    width: 100%;
    min-height: 100%;

    padding: ${gridGutters(1)};
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    > :first-child {
        margin-bottom: ${gridGutters(1)};
    }
`;

export const DialogueBoxLayout = styled.div`
    width: 100%;
    max-width: ${gridSquares(14)};

    display: flex;
`;

export const GridLayout = styled.div`
    width: 100%;
    flex-grow: 1;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: ${gridGutters(8)} ${gridGutters(5)} auto ${gridGutters(
            5
        )} ${gridGutters(5)} 1fr;

    border: ${(p) => p.theme.lessonBorder};
`;

export const HeaderLayout = styled.header`
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;

    padding-left: ${gridGutters(1)};

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: ${(p) => p.theme.lessonBorder};
    background: ${(p) => p.theme.color.background[2]};

    > :not(:first-child) {
        margin-left: ${gridGutters(1)};
    }
`;

export const Icon = styled.img`
    width: ${gridSquares(1)};
    height: ${gridSquares(1)};

    object-fit: contain;
`;

// headings ----------------------------------------------------------------------
export const SectionHeadingLayout = styled.div`
    padding-left: ${gridGutters(1)};

    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const ExampleTemplateHeadingLayout = styled(SectionHeadingLayout)`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
`;

export const InputHeadingLayout = styled(SectionHeadingLayout)`
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;

    border-left: ${(p) => p.theme.lessonBorder};
`;

export const SystemsHeadingLayout = styled(SectionHeadingLayout)`
    grid-column-start: 1;
    /* grid-column-end: 3; */
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;

    border-bottom: ${(p) => p.theme.lessonBorder};
`;

// --------------------------------------------------------------------------------
export const ExampleLayout = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;

    user-select: none;
    border-top: ${(p) => p.theme.lessonBorder};
    border-bottom: ${(p) => p.theme.lessonBorder};
`;

export const AnswerInputLayout = styled.div`
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 7;

    border-top: ${(p) => p.theme.lessonBorder};
    border-left: ${(p) => p.theme.lessonBorder};
`;

export const CompleteLayout = styled.div`
    position: fixed;
    bottom: ${gridGutters(2)};
    right: ${gridGutters(2)};
    z-index: 10;

    padding: ${gridGutters(2)};
    display: flex;
    align-items: center;

    background: ${(p) => p.theme.color.background[0]};
    border: ${(p) => p.theme.lessonBorder};
`;

// systems --------------------------------------------------------------------------------
export const SystemsLayout = styled.ul`
    grid-column-start: 1;
    /* grid-column-end: 3; */
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 7;

    display: flex;
    flex-direction: column;
`;

export const System = styled.li`
    padding-left: ${gridGutters(1)};
    border-bottom: ${(p) => p.theme.lessonBorder};

    display: flex;
    align-items: center;

    > :first-child {
        margin-right: auto;
    }

    > :nth-child(2) {
        border-left: ${(p) => p.theme.lessonBorder};
    }
`;

export const Status = styled.div<{
    isColored: boolean;
    isInactive?: boolean;
    isLast: boolean;
}>`
    ${({ isInactive, isColored, isLast, theme }) => css`
        width: ${gridGutters(20)};
        padding: ${gridGutters(1)} 0;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        ${isColored &&
        css`
            background: ${isInactive
                ? theme.color.red[1]
                : theme.color.green[0]};

            animation-name: ${mountFlickerAnimationKeyframes};
            animation-duration: ${MOUNT_FLICKER_ANIMATION_DURATION_SECONDS}s;
            animation-timing-function: linear;
            animation-fill-mode: both;

            > * {
                color: ${theme.color.text[0]};
            }

            ${!isLast &&
            css`
                ::after {
                    content: "";
                    width: 100%;

                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    border-bottom: 2px solid ${theme.color.gray[0]};
                }
            `}
        `}
    `}
`;
