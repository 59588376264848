import { assign, createMachine, sendParent } from "xstate";
import { AnswerContextType, AnswerEventType, AnswerStateType } from "./types";
import { log } from "xstate/lib/actions";

export const STARTING_ANSWER = `
const config = {
    
}
`.trim();

export const answerMachine = createMachine<
    AnswerContextType,
    AnswerEventType,
    AnswerStateType
>(
    {
        context: {
            answer: STARTING_ANSWER,
            cursorStartingPosition: {
                line: 1,
                ch: 4,
            },
        },
        id: "answer",
        initial: `active`,
        states: {
            active: {
                on: {
                    ANSWER_ENTERED: {
                        actions: [`broadcast`, `updateAnswer`],
                    },
                    CLEAR_ANSWER: {
                        actions: [`clearAnswer`, log()],
                    },
                },
            },
        },
    },
    {
        actions: {
            broadcast: sendParent((_, event) => event),
            // @ts-ignore
            clearAnswer: assign<AnswerContextType>(() => ({
                answer: `
const config = {

}
`.trim(),
            })),
            // @ts-ignore
            updateAnswer: assign<AnswerContextType, AnswerEnteredEventType>(
                (_, event) => ({
                    answer: event.payload,
                })
            ),
        },
    }
);
