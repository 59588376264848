import { createMachine, sendParent, assign } from "xstate";
import { StockEventType, StockStateType, StockContextType } from "./types";
import { itemsArray } from "../../items";
import {
    ItemButtonClickedEventType,
    CoordButtonClickedEventType,
    DirectionButtonClickedEventType,
    HighlightStockBarItemEventType,
} from "../gameMachine/types";
import { ItemType } from "../../types";
import { calcDirectionButtonCoords } from "../../util/calcDirectionButtonCoords";
import { calcDeployGridCoordsList } from "../../util/calcDeployGridCoordsList";

export const stockMachine = createMachine<
    StockContextType,
    StockEventType,
    StockStateType
>(
    {
        id: `stock`,
        context: {
            boardPieces: [],
            deployGridCoordsList: undefined,
            directionButtonCoords: undefined,
            highlightItemKey: undefined,
            items: itemsArray,
            selectedItemKey: undefined,
            selectedItemSettings: undefined,
        },
        initial: `selectingItem`,
        on: {
            HIGHLIGHT_STOCK_BAR_ITEM: {
                actions: `highlightItem`,
            },
            CLEAR_HIGHLIGHT_STOCK_BAR_ITEM: {
                actions: `clearHighlight`,
            },
        },
        states: {
            selectingItem: {
                entry: `showFogOfWar`,
                on: {
                    FRAME: {
                        actions: `updateBoardPieces`,
                    },
                    ITEM_BUTTON_CLICKED: [
                        {
                            cond: `isStockRemaining`,
                            actions: [`selectItem`, `setDeployGridCoords`],
                            target: `selectingItemCoords`,
                        },
                    ],
                    LESSON_COMPLETE: {
                        actions: `reduceStock`,
                        target: `determining`,
                    },
                },
            },
            determining: {
                exit: `clearSelection`,
                always: [
                    {
                        // actions: `unlockItem`,
                        cond: `isSelectedItemOutOfStockAndNotLastItem`,
                        target: `selectingItem`,
                    },
                    {
                        target: `selectingItem`,
                    },
                ],
            },
            selectingItemDirection: {
                exit: `clearDialogue`,
                on: {
                    DIRECTION_BUTTON_CLICKED: {
                        actions: [
                            `updateSelectedItemDirectionSettings`,
                            `broadcastDeployComplete`,
                        ],
                        target: "selectingItem",
                    },
                },
            },
            selectingItemCoords: {
                entry: [`setSelectCoordsDialogue`, `hideFogOfWar`],
                on: {
                    COORD_BUTTON_CLICKED: [
                        {
                            actions: [
                                `updateSelectedItemCoordsSettings`,
                                `calcDirectionButtonCoords`,
                                `setSelectDirectionDialogue`,
                            ],
                            cond: `doesItemHaveDirection`,
                            target: `selectingItemDirection`,
                        },
                        {
                            actions: [
                                `updateSelectedItemCoordsSettings`,
                                `broadcastDeployComplete`,
                                `clearDialogue`,
                            ],
                            target: "selectingItem",
                        },
                    ],
                },
            },
        },
    },
    {
        actions: {
            // @ts-ignore
            clearHighlight: assign<StockContextType>(() => ({
                highlightItemKey: undefined,
            })),
            broadcast: sendParent((_, event) => event),
            broadcastDeployComplete: sendParent((context) => ({
                type: "DEPLOY_COMPLETE",
                payload: {
                    itemKey: context.selectedItemKey,
                    itemSettings: context.selectedItemSettings,
                },
            })),
            // @ts-ignore
            calcDirectionButtonCoords: assign<StockContextType>((context) => {
                const coords = context.selectedItemSettings?.coords;

                if (!coords) {
                    throw Error(`No coords set for selected item.`);
                }

                return {
                    directionButtonCoords: calcDirectionButtonCoords(coords),
                };
            }),
            clearDialogue: sendParent(() => ({
                type: "CLEAR_DIALOGUE",
            })),
            // @ts-ignore
            clearSelection: assign<StockContextType>(() => ({
                directionButtonCoords: undefined,
                selectedItemKey: undefined,
                selectedItemSettings: undefined,
            })),
            hideFogOfWar: sendParent("HIDE_FOG_OF_WAR"),
            // @ts-ignore
            highlightItem: assign<
                StockContextType,
                HighlightStockBarItemEventType
            >((_, event) => ({
                highlightItemKey: event.payload,
            })),
            // @ts-ignore
            selectItem: assign<StockContextType, ItemButtonClickedEventType>(
                (_, event) => ({
                    selectedItemKey: event.payload,
                })
            ),
            setSelectCoordsDialogue: sendParent(() => ({
                type: "SET_DIALOGUE",
                payload: {
                    dialogue: `Select location.`,
                    isNextButton: false,
                },
            })),
            setSelectDirectionDialogue: sendParent(() => ({
                type: "SET_DIALOGUE",
                payload: {
                    dialogue: `Select direction.`,
                    isNextButton: false,
                },
            })),
            //@ts-ignore
            setDeployGridCoords: assign<
                StockContextType,
                ItemButtonClickedEventType
            >((context, event) => ({
                deployGridCoordsList: calcDeployGridCoordsList({
                    itemKey: event.payload,
                    boardPieces: context.boardPieces,
                }),
            })),
            showFogOfWar: sendParent("SHOW_FOG_OF_WAR"),
            // @ts-ignore
            reduceStock: assign<StockContextType>((context) => ({
                items: context.items.map((item) =>
                    item.itemKey === context.selectedItemKey
                        ? {
                              ...item,
                              numberInStock: item.numberInStock - 1,
                          }
                        : item
                ),
            })),
            // @ts-ignore
            unlockItem: assign<StockContextType>(
                (context: StockContextType) => {
                    const { items } = context;
                    const nextItem = items.find(
                        (item) => item.numberInStock > 0
                    );

                    return {
                        items: items.map((item) =>
                            item.itemKey === nextItem?.itemKey
                                ? {
                                      ...item,
                                      isUnlocked: true,
                                  }
                                : item
                        ),
                    };
                }
            ),
            // @ts-ignore
            updateBoardPieces: assign<StockContextType, FrameEventType>(
                (_, event) => ({
                    boardPieces: event.payload.boardPieces,
                })
            ),
            // @ts-ignore
            updateSelectedItemDirectionSettings: assign<
                StockContextType,
                DirectionButtonClickedEventType
            >(
                // @ts-ignore
                (context, event) => ({
                    selectedItemSettings: {
                        ...context.selectedItemSettings,
                        direction: event.payload,
                    },
                })
            ),
            //@ts-ignore
            updateSelectedItemCoordsSettings: assign<
                StockContextType,
                CoordButtonClickedEventType
            >((context, event) => ({
                selectedItemSettings: {
                    ...context.selectedItemSettings,
                    coords: event.payload,
                },
            })),
        },
        guards: {
            // @ts-ignore
            doesItemHaveDirection: (context) => {
                const { selectedItemKey, items } = context;

                return items.find((item) => item.itemKey === selectedItemKey)
                    ?.hasDirection;
            },
            // @ts-ignore
            isStockRemaining: (
                context: StockContextType,
                event: ItemButtonClickedEventType
            ) => {
                const itemKey = event.payload;
                const item = context.items.find(
                    (item_) => item_.itemKey === itemKey
                ) as ItemType;

                return item?.numberInStock > 0;
            },
            // @ts-ignore
            isSelectedItemOutOfStockAndNotLastItem: (
                context: StockContextType
            ) => {
                const { selectedItemKey } = context;
                const item = context.items.find(
                    (item_) => item_.itemKey === selectedItemKey
                );

                return item?.numberInStock === 0;
            },
        },
    }
);
