import { DirectionType } from "../types";
import { IconKeyType } from "../components/Icon/types";

type DirectionIconMapType = {
    [propName in DirectionType]: IconKeyType;
};

export const directionToIconMap: DirectionIconMapType = {
    [DirectionType.Up]: IconKeyType.upArrow,
    [DirectionType.UpRight]: IconKeyType.topRightArrow,
    [DirectionType.Right]: IconKeyType.rightArrow,
    [DirectionType.DownRight]: IconKeyType.bottomRightArrow,
    [DirectionType.Down]: IconKeyType.downArrow,
    [DirectionType.DownLeft]: IconKeyType.bottomLeftArrow,
    [DirectionType.Left]: IconKeyType.leftArrow,
    [DirectionType.UpLeft]: IconKeyType.topLeftArrow,
};
