import { DefaultTheme } from "styled-components";
import { gridSquares } from "./grid";

export const baseColor = {
    blue: [`#010b12`],
    brown: [`#d1ab7e`],
    gray: [
        `hsla(0, 0%, 5%, 1)`,
        `hsla(0, 0%, 13%, 1)`,
        `hsla(0, 0%, 26%, 1)`,
        `hsla(0, 0%, 38%, 1)`,
        `hsla(0, 0%, 46%, 1)`,
        `hsla(0, 0%, 62%, 1)`,
        `hsla(0, 0%, 74%, 1)`,
        `hsla(0, 0%, 88%, 1)`,
        `hsla(0, 0%, 93%, 1)`,
        `hsla(0, 0%, 96%, 1)`,
        `hsla(0, 0%, 100%, 1)`,
    ],
    green: [`#4caf50`],
    orange: [`hsla(14, 91%, 54%, 1)`],
    red: [`red`, `#c62828`],
};

const clickable = baseColor.orange;
const BORDER_COLOR = `#fff`;

const theme: DefaultTheme = {
    border: `2px solid ${BORDER_COLOR}`,
    borderRadius: `0px`,
    buttonBorder: `2px solid ${clickable[0]}`,
    color: {
        ...baseColor,
        active: `#123a58`,
        background: [`#010816`, `#00263d`, `#123a58`],
        border: BORDER_COLOR,
        clickable,
        focus: baseColor.gray[3],
        fogOfWar: `hsla(0, 0%, 0%, 0.7)`,
        hover: `hsla(14, 91%, 54%, 0.5)`,
        overlay: `hsla(0, 0%, 5%, 0.8)`,
        text: baseColor.gray,
    },
    fontFamily: [`Orbitron, sans-serif`],
    fontSize: [`14px`, `16px`, `20px`, `24px`],
    fontWeight: [500, 800],
    lessonBorder: `2px solid #123a58`,
    lineHeight: [1.4],
    maxWidth: {
        paragraph: gridSquares(13),
    },
    opacity: {
        disabled: 0.2,
    },
};

export { theme };
