import { CoordsType } from "../types";
import { flatten } from "lodash";

interface PropsType {
    centerCoords: CoordsType;
    dimension: number;
}

export const calcCoordsSquare = ({ centerCoords, dimension }: PropsType): CoordsType[] => {
    const [x, y] = centerCoords;
    const distanceToStart = Math.floor(dimension / 2);
    const startX = x - distanceToStart;
    const startY = y - distanceToStart;

    return flatten(
        Array(dimension)
            .fill(undefined)
            .map((_, i) =>
                Array(dimension)
                    .fill([undefined, startY + i])
                    .map(([_, y], i) => [startX + i, y])
            )
    ) as CoordsType[];
};
