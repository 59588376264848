import React, { FC } from "react";
import { useService } from "@xstate/react";
import { BasicGunActorType } from "../../../machines/itemMachines/basicGunMachine/types";
import { BasicGun } from ".";

interface PropsType {
    actor: BasicGunActorType;
}

export const BasicGunWithActor: FC<PropsType> = ({ actor }) => {
    // @ts-ignore
    const [state] = useService(actor);
    const { coords, killZoneCoordsList } = state.context;

    return (
        <BasicGun
            coords={coords}
            isFiring={state.matches("firing")}
            killZoneCoordsList={killZoneCoordsList}
        />
    );
};
