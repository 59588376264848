import { createMachine } from "xstate";
import { QueenEventType, QueenStateType } from "./types";
import { sendParent } from "xstate/lib/actions";
import { FrameEventType } from "../gameMachine/types";
import {
    ALIEN_SPAWN_CADENCE_FRAME_COUNT,
    ALIEN_SPAWN_AMOUNT_INCREASE,
} from "../../constants";
import { calcPathId } from "../../util/calcPathId";

export const queenMachine = createMachine<null, QueenEventType, QueenStateType>(
    {
        id: `queen`,
        initial: `active`,
        states: {
            active: {
                on: {
                    FRAME: [
                        {
                            actions: "spawnAliens",
                            cond: "isSpawnFrame",
                        },
                    ],
                },
            },
        },
    },
    {
        actions: {
            spawnAliens: sendParent((_, event: FrameEventType) => ({
                type: "SPAWN_ALIENS",
                payload: {
                    amount:
                        (event.payload.frameCount /
                            ALIEN_SPAWN_CADENCE_FRAME_COUNT) *
                        ALIEN_SPAWN_AMOUNT_INCREASE,
                    pathId: calcPathId(event.payload.frameCount),
                },
            })),
        },
        guards: {
            isSpawnFrame: (_, event: FrameEventType) => {
                const { frameCount } = event.payload;

                return (
                    frameCount !== 0 &&
                    frameCount % ALIEN_SPAWN_CADENCE_FRAME_COUNT === 0
                );
            },
        },
    }
);
