import { GameContextType } from "../machines/gameMachine/types";
import { BoardPieceKeyType } from "../types";
import { AlienActorType } from "../machines/alienMachine/types";
import { SonarActorType } from "../machines/itemMachines/sonarMachine/types";
import { BasicGunActorType } from "../machines/itemMachines/basicGunMachine/types";
import { allItemKeys } from "../items";
import { ItemActorType } from "../machines/itemMachines/types";

type BoardPiecesType = GameContextType["boardPieces"];

export const getItemPieces = (boardPieces: BoardPiecesType) =>
    // @ts-ignore
    boardPieces.filter((actor) => allItemKeys.includes(actor.machine.id)) as ItemActorType[];

export const getDestructableItemPieces = (boardPieces: BoardPiecesType) =>
    // @ts-ignore
    boardPieces.filter((actor) => actor.machine.meta?.isDestructable) as ItemActorType[];

export const getBlockingItemPieces = (boardPieces: BoardPiecesType) =>
    // @ts-ignore
    getItemPieces(boardPieces).filter((actor) => !actor.machine.meta?.isNonBlocking) as ItemActorType[];

export const getBoardPieceKeyPieces = ({
    boardPieces,
    boardPieceKey,
}: {
    boardPieces: BoardPiecesType;
    boardPieceKey: BoardPieceKeyType;
}) =>
    // @ts-ignore
    boardPieces.filter((actor) => actor.machine.id === boardPieceKey) as BoardPieceActorType[];

// ---------------------------------------------------------------------------------------------------------------------

export const getAlienPieces = (boardPieces: BoardPiecesType) =>
    // @ts-ignore
    getBoardPieceKeyPieces({ boardPieces, boardPieceKey: BoardPieceKeyType.alien }) as AlienActorType[];

export const getSonarPieces = (boardPieces: BoardPiecesType) =>
    // @ts-ignore
    getBoardPieceKeyPieces({ boardPieces, boardPieceKey: BoardPieceKeyType.sonar }) as SonarActorType[];

export const getGunPieces = (boardPieces: BoardPiecesType) =>
    // @ts-ignore
    getBoardPieceKeyPieces({ boardPieces, boardPieceKey: BoardPieceKeyType.basicGun }) as BasicGunActorType[];
