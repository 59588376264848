import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;

        margin: 0;
        padding: 0;

        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body {
        width: 100%;
        min-height: 100vh;

        display: flex;
        justify-content: center;
        
        background: ${theme.color.background[0]};
    }

    #root {
        width: 100%;
        min-height: 100vh;

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        font-size: ${theme.fontSize[1]};
        font-family: ${theme.fontFamily[0]};
        font-weight: ${theme.fontWeight[0]};
        line-height: ${theme.lineHeight[0]};

        color: ${theme.color.text[6]};
    }

    ul {
        list-style: none;
    }

    video,
    iframe {
        /* remove pixels below display: inline */
        display: block;
        /* so imgs will resize based on wrapper constraints */
        width: 100%;
        height: auto;
        /* prevent image from stretching due to 'align-self: default' value is stretch (in flexbox context) */
        align-self: flex-start;
        border: none;
    }

    img {
        /* remove pixels below display: inline */
        display: block;
    }

    a {
        text-decoration: none;
    }

    button {
        background: transparent;
        border: none;
        cursor: pointer;
    }

    address,
    em {
        font-style: normal;
    }

    [disabled] {
        pointer-events: none;
    }

    :focus {
        outline: 1px solid ${theme.color.focus};
    }

    /* prevents focus after click, see layouts/index */
    body:not(.user-is-tabbing) a:focus,
    body:not(.user-is-tabbing) button:focus,
    body:not(.user-is-tabbing) input:focus,
    body:not(.user-is-tabbing) select:focus,
    body:not(.user-is-tabbing) textarea:focus {
        outline: none;
    }
`;
