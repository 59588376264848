import React, { FC } from "react";
import { Markdown } from "../Typography/Markdown";
import { Button } from "../Button";
import styled, { css } from "styled-components";
import { gridGutters } from "../../styles/grid";
import { GridGutterRows } from "../Grid";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";

const PADDING = gridGutters(1);

export const OuterLayout = styled.section`
    ${({ theme }) => css`
        width: 100%;
        max-width: calc(${theme.maxWidth.paragraph} + ${PADDING} * 2);

        display: flex;
    `}
`;

export const InnerLayout = styled.section`
    ${({ theme }) => css`
        width: 100%;

        padding: ${PADDING};
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        border-radius: ${theme.borderRadius};
        border: ${theme.border};
        background: ${theme.color.background[0]};
    `}
`;

interface PropsType {
    dialogueMarkdown: string;
    onNextClick?: () => void;
}

export const DialogueBox: FC<PropsType> = ({ dialogueMarkdown, onNextClick }) => (
    <OuterLayout>
        <MountFlickerAnimation style={{ width: `100%` }}>
            <InnerLayout>
                <Markdown markdown={dialogueMarkdown} />
                {onNextClick && (
                    <>
                        <GridGutterRows amount={2} />
                        <Button onClick={onNextClick}>Next</Button>
                    </>
                )}
            </InnerLayout>
        </MountFlickerAnimation>
    </OuterLayout>
);
