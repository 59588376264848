import React, { FC } from "react";
import { CoordsSquare } from "../../Map/CoordsSquare";
import { CoordsType, ItemKeyType } from "../../../types";
import styled from "styled-components";
import { itemsObject } from "../../../items";
import killZoneConeIcon from "../../../images//icons/killZoneConeIcon.png";
import killZoneConeFiringIcon from "../../../images//icons/killZoneConeFiringIcon.png";
import { calcDirection } from "../../../util/calcDirection";
import { directionToDegreesMap } from "../../../util/directionToDegreesMap";

const Layout = styled.div<{ degrees: number }>`
    display: flex;

    transform-origin: 50% 50%;
    transform: rotate(${(p) => p.degrees}deg);
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
`;

interface PropsType {
    coords: CoordsType;
    isFiring: boolean;
    killZoneCoordsList: CoordsType[];
}

export const BasicGun: FC<PropsType> = ({
    coords,
    isFiring,
    killZoneCoordsList,
}) => {
    const direction = calcDirection({
        originCoords: coords,
        targetCoords: killZoneCoordsList[0],
    });
    const degrees = directionToDegreesMap[direction];

    return (
        <>
            <CoordsSquare coords={coords} color="transparent">
                <Layout degrees={degrees}>
                    <Img src={itemsObject[ItemKeyType.basicGun].icon} />
                </Layout>
            </CoordsSquare>
            {killZoneCoordsList.map((killZoneCoords) => (
                <CoordsSquare
                    key={killZoneCoords.join()}
                    coords={killZoneCoords}
                    color="transparent"
                >
                    <Layout degrees={degrees}>
                        <Img
                            src={
                                isFiring
                                    ? killZoneConeFiringIcon
                                    : killZoneConeIcon
                            }
                        />
                    </Layout>
                </CoordsSquare>
            ))}
        </>
    );
};
