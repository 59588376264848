import { CoordsType } from "../types";

export const controlRoomCoordsList: CoordsType[] = [
    [14, 12],
    [14, 13],
    [14, 14],
    [14, 15],
    [14, 16],

    [15, 12],
    [15, 13],
    [15, 14],
    [15, 15],
    [15, 16],

    [16, 12],
    [16, 13],
    [16, 14],
    [16, 15],
    [16, 16],

    [17, 12],
    [17, 13],
    [17, 14],
    [17, 15],
    [17, 16],

    [18, 12],
    [18, 13],
    [18, 14],
    [18, 15],
    [18, 16],
];
