import { createMachine, sendParent } from "xstate";
import {
    NotificationEventType,
    NotificationStateType,
    NotificationContextType,
} from "./types";

interface PropsType {
    id: string;
    text: string;
}

export const notificationMachine = ({ id, text }: PropsType) =>
    createMachine<
        NotificationContextType,
        NotificationEventType,
        NotificationStateType
    >(
        {
            id: `notification`,
            context: {
                text,
            },
            initial: `active`,
            states: {
                active: {
                    after: {
                        8000: {
                            actions: `removeNotification`,
                        },
                    },
                },
            },
        },
        {
            actions: {
                removeNotification: sendParent({
                    type: "REMOVE_NOTIFICATION",
                    payload: id,
                }),
            },
        }
    );
