import { DirectionType } from "../types";

export const directionToDegreesMap: Record<DirectionType, number> = {
    [DirectionType.Up]: 0,
    [DirectionType.UpRight]: 45,
    [DirectionType.Right]: 90,
    [DirectionType.DownRight]: 135,
    [DirectionType.Down]: 180,
    [DirectionType.DownLeft]: 225,
    [DirectionType.Left]: 270,
    [DirectionType.UpLeft]: 315,
};
