import { IconKeyType } from "./types";
import React, { ReactNode } from "react";

export const shapes: Record<IconKeyType, ReactNode> = {
    [IconKeyType.bottomLeftArrow]: <path d="M3.51,3.51v17h17Z" />,
    [IconKeyType.bottomRightArrow]: <path d="M3.51,20.49h17v-17Z" />,
    [IconKeyType.close]: (
        <path d="M24,2.42,21.58,0,12,9.58,2.42,0,0,2.42,9.58,12,0,21.58,2.42,24,12,14.42,21.58,24,24,21.58,14.42,12Z" />
    ),
    [IconKeyType.downArrow]: <path d="M0,6,12,18,24,6Z" />,
    [IconKeyType.email]: (
        <path d="M21.6,2.4H2.4A2.4,2.4,0,0,0,0,4.8V19.2a2.41,2.41,0,0,0,2.4,2.4H21.6A2.41,2.41,0,0,0,24,19.2V4.8A2.41,2.41,0,0,0,21.6,2.4Zm0,4.8-9.6,6-9.6-6V4.8l9.6,6,9.6-6Z" />
    ),
    [IconKeyType.leftArrow]: <path d="M18,0,6,12,18,24Z" />,
    [IconKeyType.location]: (
        <path d="M12,11.4a3,3,0,1,1,3-3,3,3,0,0,1-3,3M12,0A8.4,8.4,0,0,0,3.6,8.4C3.6,14.7,12,24,12,24s8.4-9.3,8.4-15.6A8.4,8.4,0,0,0,12,0Z" />
    ),
    [IconKeyType.menu]: <path d="M0,20H24V17.33H0Zm0-6.67H24V10.67H0ZM0,4V6.67H24V4Z" />,
    [IconKeyType.rightArrow]: <path d="M6,24,18,12,6,0Z" />,
    [IconKeyType.topLeftArrow]: <path d="M20.49,3.51h-17v17Z" />,
    [IconKeyType.topRightArrow]: <path d="M20.49,20.49v-17h-17Z" />,
    [IconKeyType.twitter]: (
        <path d="M24,4.56a9.87,9.87,0,0,1-2.83.77,4.94,4.94,0,0,0,2.17-2.72A10,10,0,0,1,20.21,3.8a4.93,4.93,0,0,0-8.52,3.37,5.12,5.12,0,0,0,.13,1.12A14,14,0,0,1,1.67,3.15,4.92,4.92,0,0,0,3.19,9.72,4.86,4.86,0,0,1,1,9.11v.06a4.94,4.94,0,0,0,4,4.83,5,5,0,0,1-1.29.17,4.73,4.73,0,0,1-.93-.09,4.93,4.93,0,0,0,4.6,3.42,9.9,9.9,0,0,1-6.12,2.11A9.18,9.18,0,0,1,0,19.54a13.92,13.92,0,0,0,7.55,2.21,13.92,13.92,0,0,0,14-14c0-.21,0-.42,0-.63A10.1,10.1,0,0,0,24,4.56Z" />
    ),
    [IconKeyType.upArrow]: <path d="M0,18,12,6,24,18Z" />,
};
