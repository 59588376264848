import styled, { keyframes } from "styled-components";
import { SLOW_BLINKING_ANIMATION_DURATION_SECONDS, FAST_BLINKING_ANIMATION_DURATION_SECONDS } from "../../constants";

const blinkKeyframes = keyframes`
    0%   { opacity: 1; }
    50%  { opacity: 1; }
    51%  { opacity: 0; }
    100% { opacity: 0; }
`;

interface PropsType {
    durationSeconds?: number;
}

export const LookAtBlinkingAnimation = styled.div<PropsType>`
    animation-name: ${blinkKeyframes};
    animation-duration: ${SLOW_BLINKING_ANIMATION_DURATION_SECONDS}s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    display: flex;
`;

export const ClickMeBlinkingAnimation = styled(LookAtBlinkingAnimation)`
    animation-duration: ${FAST_BLINKING_ANIMATION_DURATION_SECONDS}s;
`;
