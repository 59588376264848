import { createMachine, sendParent } from "xstate";
import {
    BasicGunEventType,
    BasicGunStateType,
    BasicGunContextType,
} from "./types";
import { BoardPieceKeyType, ItemSettingsType } from "../../../types";
import { calcKillZoneCoordsList } from "./calcKillZoneCoordsList";
import { FrameEventType } from "../../gameMachine/types";
import { isEqual, intersectionWith } from "lodash";
import { getAlienPieces } from "../../../util/filterBoardPieces";
import { playBasicGunFiringSound } from "../../../sounds";

export const basicGunMachine = (itemSettings: ItemSettingsType) => {
    const { coords, direction } = itemSettings;

    if (!direction) {
        throw Error(`basicGunMachine required direction`);
    }

    return createMachine<
        BasicGunContextType,
        BasicGunEventType,
        BasicGunStateType
    >(
        {
            context: {
                coords,
                killZoneCoordsList: calcKillZoneCoordsList({
                    coords,
                    direction,
                }),
            },
            id: BoardPieceKeyType.basicGun,
            initial: `active`,
            meta: {
                isDestructable: true,
            },
            states: {
                active: {
                    on: {
                        FRAME: [
                            {
                                actions: [`killAlien`, `playSound`],
                                cond: `isAlienInKillZoneAndOddFrame`,
                                target: `firing`,
                            },
                        ],
                    },
                },
                firing: {
                    after: {
                        1000: "active",
                    },
                },
            },
        },
        {
            actions: {
                killAlien: sendParent(
                    (context: BasicGunContextType, event: FrameEventType) => {
                        const { killZoneCoordsList } = context;
                        const { boardPieces } = event.payload;
                        const aliens = getAlienPieces(boardPieces);
                        const aliensInKillZone = intersectionWith(
                            aliens,
                            killZoneCoordsList,
                            (alien, killZoneCoords) =>
                                isEqual(
                                    alien.state.context.coords,
                                    killZoneCoords
                                )
                        );
                        const id = aliensInKillZone[0]?.id;

                        return {
                            type: "KILL_ALIEN",
                            payload: id,
                        };
                    }
                ),
                playSound: () => playBasicGunFiringSound(),
            },
            guards: {
                isAlienInKillZoneAndOddFrame: (
                    context: BasicGunContextType,
                    event: FrameEventType
                ) => {
                    const { killZoneCoordsList } = context;
                    const { boardPieces, frameCount } = event.payload;
                    const aliens = getAlienPieces(boardPieces);

                    // I'm using this to limit to rate of fire to every 2nd frame, a more robust solution would be required in future
                    if (frameCount % 2 === 0) {
                        return false;
                    }

                    return (
                        intersectionWith(
                            aliens,
                            killZoneCoordsList,
                            (alien, killZoneCoords) =>
                                isEqual(
                                    alien.state.context.coords,
                                    killZoneCoords
                                )
                        ).length > 0
                    );
                },
            },
        }
    );
};
