import React, { FC, memo } from "react";
import { CoordsType } from "../../types";
import { coordsToCenterPosition } from "../../coords";
import styled, { css } from "styled-components";
import { SonarFadeAnimation } from "../Animations/SonarFadeAnimation";

export const Layout = styled.div`
    ${({ theme }) => css`
        width: 4px;
        height: 4px;

        position: absolute;
        transform: translate(-50%, -50%);

        background: ${theme.color.red[0]};
        border-radius: 50%;
        box-shadow: 0px 0px 30px 10px ${theme.color.red[0]}, 0px 0px 10px 5px ${theme.color.red[0]};
    `}
`;

interface PropsType {
    coords: CoordsType;
}

export const Alien: FC<PropsType> = memo(({ coords }) => {
    const [left, top] = coordsToCenterPosition(coords);

    return (
        <SonarFadeAnimation>
            <Layout style={{ left: `${left}px`, top: `${top}px` }} />
        </SonarFadeAnimation>
    );
});
