import { StateMachine } from "xstate";

// Machine properties, accessors, ...
// https://xstate.js.org/api/interfaces/statemachine.html

type MachineType = StateMachine<any, any, any>;

export type StatePathType = string;

interface DoesMachineHaveInitialStatePropsType {
    machine: MachineType;
    initialStateKey: string;
}

export const doesMachineHaveInitialState = ({
    machine,
    initialStateKey,
}: DoesMachineHaveInitialStatePropsType): boolean => {
    try {
        return machine.initialState.value === initialStateKey;
    } catch (error) {
        return false;
    }
};

interface DoesMachineHaveStatePathPropsType {
    machine: MachineType;
    statePath: StatePathType;
}

export const doesMachineHaveStatePath = ({ machine, statePath }: DoesMachineHaveStatePathPropsType): boolean => {
    try {
        // this will throw if can't find statePath
        machine.getStateNodeByPath(statePath);
        return true;
    } catch (error) {
        return false;
    }
};

export interface TransitionType {
    eventType: string;
    sourceStatePath: string;
    targetStateKey: string;
}

interface DoesMachineHaveTransitionPropsType {
    machine: MachineType;
    transition: TransitionType;
}

export const doesMachineHaveTransition = ({ machine, transition }: DoesMachineHaveTransitionPropsType): boolean => {
    try {
        const stateNode = machine.getStateNodeByPath(transition.sourceStatePath);
        const { transitions } = stateNode;

        return !!transitions.find((t) => {
            const hasEvent = t.eventType === transition.eventType;
            const targetStateNodes = t.target;
            // @ts-ignore
            const targetStateKeys = targetStateNodes.map((t) => t.key);
            const hasTarget = targetStateKeys.includes(transition.targetStateKey);

            return hasEvent && hasTarget;
        });
    } catch (error) {
        return false;
    }
};
