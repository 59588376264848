import styled from "styled-components";
import { LabelText } from "./Typography/LabelText";
import { FC } from "react";
import React from "react";
import { gridGutters } from "../styles/grid";

const Layout = styled.div`
    padding: ${gridGutters(1)};

    pointer-events: none;
    background: ${(p) => p.theme.color.background[0]};
    border: ${(p) => p.theme.border};
`;

export const Tooltip: FC = (props) => (
    <Layout>
        <LabelText {...props} />
    </Layout>
);
