import { paths, PathId } from "../coords/paths";
import { random } from "lodash";
import { PATH_UNLOCK_CADENCE_FRAME_COUNT } from "../constants";

export const calcPathId = (frameCount: number) => {
    const pathKeys = Object.keys(paths) as PathId[];
    const unlockedKeys = pathKeys.filter((key, i) => i * PATH_UNLOCK_CADENCE_FRAME_COUNT < frameCount);

    const i = random(unlockedKeys.length - 1);

    return pathKeys[i];
};
