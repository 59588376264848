import React, { FC } from "react";
import styled from "styled-components";
import { CoordsType } from "../../types";
import { coordsToLeftTopPosition, MAP_SQUARE_DIMENSION } from "../../coords";
import { playbuttonClickSound } from "../../sounds";
import { GridButton } from "../GridButton";

const Layout = styled.div`
    width: ${MAP_SQUARE_DIMENSION}px;
    height: ${MAP_SQUARE_DIMENSION}px;

    position: absolute;

    padding: 1px;
    display: flex;
`;

interface PropsType {
    onClick: () => void;
    coords: CoordsType;
}

export const Button: FC<PropsType> = ({ coords, onClick }) => {
    const [left, top] = coordsToLeftTopPosition(coords);

    return (
        <Layout style={{ left: `${left}px`, top: `${top}px` }}>
            <GridButton
                onClick={() => {
                    onClick();
                    playbuttonClickSound();
                }}
            />
        </Layout>
    );
};
