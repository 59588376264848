import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import { SONAR_ANIMATION_DURATION_SECONDS } from "../../constants";

const fadeAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Svg = styled.svg<PropsType>`
    width: 100%;
    height: 100%;

    animation: ${fadeAnimation} ${(p) => p.durationSeconds}s linear infinite;

    circle {
        fill: none;
        stroke: ${(p) => p.theme.color.border};
    }
`;

interface PropsType {
    durationSeconds?: number;
}

export const RadiatingCircle: FC<PropsType> = ({ durationSeconds = SONAR_ANIMATION_DURATION_SECONDS }) => (
    <Svg viewBox="0 0 100 100" durationSeconds={durationSeconds}>
        <circle cx="0" cy="0" r="50" transform="translate(50 50)" vectorEffect="non-scaling-stroke">
            <animateTransform
                attributeName="transform"
                type="scale"
                additive="sum"
                from="0 0"
                to="1 1"
                begin="0s"
                dur={`${durationSeconds}s`}
                repeatCount="indefinite"
            />
        </circle>
    </Svg>
);
