import { createMachine, StateMachine } from "xstate";

export const extractMachineConfigStringFromAnswer = (machineConfigAnswer: string): string =>
    machineConfigAnswer.replace("const config = ", "").trim();

export const convertStringToObject = (objectAsString: string): object | undefined => {
    try {
        let obj: any;

        // eslint-disable-next-line no-eval
        obj = eval("obj=" + objectAsString);
        return obj;
    } catch (err) {
        return undefined;
    }
};

export const attemptCompileMachine = (machineConfig: object): StateMachine<any, any, any> | undefined => {
    try {
        return createMachine(machineConfig);
    } catch (err) {
        return undefined;
    }
};

export const attemptCompileConfigAnswer = (machineConfigAnswer: string): StateMachine<any, any, any> | undefined => {
    try {
        const configString = extractMachineConfigStringFromAnswer(machineConfigAnswer);

        const config = convertStringToObject(configString);

        if (config === undefined) {
            return undefined;
        }

        return attemptCompileMachine(config);
    } catch (err) {
        return undefined;
    }
};
