import { createMachine, sendParent, spawn } from "xstate";
import { IntroEventType, IntroStateType, IntroContextType } from "./types";
import { ItemKeyType } from "../../types";
import { controlRoomCoordsList } from "../../coords/controlRoomCoordsList";
import { assign, pure, forwardTo, send } from "xstate/lib/actions";
import { alienMachine } from "../alienMachine";
import { dialogueBoxMachine } from "../dialogueBoxMachine";
import { stockMachine } from "../stockMachine";
import { answerMachine } from "../answerMachine";
import { v4 as uuidV4 } from "uuid";
import { mapMachine } from "../mapMachine";
import { itemsObject } from "../../items";
import { INTRO_SONAR_SETTINGS, INTRO_SENTRY_SETTINGS } from "../../constants";
import { calcCoordsSquare } from "../../util/calcCoordsSquare";
import { SONAR_RANGE_GRID_SQUARES } from "../itemMachines/sonarMachine";
import { PathId } from "../../coords/paths";
import { renderLoop } from "../../callbacks/renderLoop";
import { itemKeyMachineMap } from "../itemMachines";

const sonarLesson = itemsObject[ItemKeyType.sonar].lessonMachine;
const basicGunLesson = itemsObject[ItemKeyType.basicGun].lessonMachine;

let frameCount = 0;

const getFrameCount = () => {
    frameCount = frameCount + 1;
    return frameCount;
};

export const introMachine = createMachine<
    IntroContextType,
    IntroEventType,
    IntroStateType
>(
    {
        context: {
            boardPieces: [],
        },
        id: `intro`,
        initial: `facility`,
        invoke: [
            {
                id: `answerMachine`,
                src: `answerMachine`,
            },
            {
                id: `dialogueBoxMachine`,
                src: `dialogueBoxMachine`,
            },
            {
                autoForward: true,
                id: `mapMachine`,
                src: `mapMachine`,
            },
            {
                autoForward: true,
                id: `stockMachine`,
                src: `stockMachine`,
            },
        ],
        states: {
            facility: {
                entry: `setFacilityDialogue`,
                on: {
                    NEXT_BUTTON_CLICKED: "marines",
                },
            },
            marines: {
                entry: `setMarinesDialogue`,
                on: {
                    NEXT_BUTTON_CLICKED: "aliens",
                },
            },
            aliens: {
                entry: [`setAliensDialogue`, `showMap`],
                on: {
                    NEXT_BUTTON_CLICKED: "evac",
                },
            },
            evac: {
                entry: [`setEvacDialogue`],
                on: {
                    NEXT_BUTTON_CLICKED: "controlRoom",
                },
            },
            controlRoom: {
                entry: [
                    `setControlRoomDialogue`,
                    `highlightControlRoom`,
                    `clearFogAroundControlRoom`,
                ],
                exit: `clearMapHighlight`,
                on: {
                    NEXT_BUTTON_CLICKED: "equipment",
                },
            },
            equipment: {
                entry: [`setEquipmentDialogue`, `showFogOfWar`],
                on: {
                    NEXT_BUTTON_CLICKED: "sonarUnit",
                },
            },
            sonarUnit: {
                entry: [`setSonarUnitDialogue`],
                on: {
                    NEXT_BUTTON_CLICKED: "clickSonar",
                },
            },
            clickSonar: {
                entry: [`setClickSonarDialogue`],
                on: {
                    ITEM_BUTTON_CLICKED: [
                        {
                            cond: (_, event) =>
                                event.payload === ItemKeyType.sonar,
                            target: "deploySonar",
                        },
                    ],
                },
            },
            deploySonar: {
                entry: [`setDeploySonarDialogue`, `hideFogOfWar`],
                on: {
                    COORD_BUTTON_CLICKED: "sonarLesson",
                },
            },
            sonarLesson: {
                invoke: {
                    id: "sonarLessonMachine",
                    src: sonarLesson.withContext({
                        ...sonarLesson.context,
                        itemKey: ItemKeyType.sonar,
                        itemSettings: INTRO_SONAR_SETTINGS,
                    }),
                },
                on: {
                    ANSWER_ENTERED: {
                        actions: forwardTo("sonarLessonMachine"),
                    },
                    LESSON_EXIT_BUTTON_CLICKED: "sonarDeployed",
                },
            },
            sonarDeployed: {
                entry: [
                    `setSonarDeployedDialogue`,
                    `clearFogAroundSonar`,
                    `showFogOfWar`,
                    `clearAnswer`,
                ],
                on: {
                    NEXT_BUTTON_CLICKED: "alienMovement",
                },
            },
            alienMovement: {
                entry: [`setAlienMovementDialogue`],
                on: {
                    NEXT_BUTTON_CLICKED: "stopAlien",
                },
            },
            stopAlien: {
                entry: [`setStopAlienDialogue`],
                on: {
                    NEXT_BUTTON_CLICKED: "clickSentry",
                },
            },
            clickSentry: {
                entry: [`setClickSentryDialogue`],
                on: {
                    ITEM_BUTTON_CLICKED: [
                        {
                            cond: (_, event) =>
                                event.payload === ItemKeyType.basicGun,
                            target: "deploySentry",
                        },
                    ],
                },
            },
            deploySentry: {
                entry: [`setDeploySentryDialogue`],
                on: {
                    COORD_BUTTON_CLICKED: "sentryLesson",
                },
            },
            sentryLesson: {
                invoke: {
                    id: "basicGunLessonMachine",
                    src: basicGunLesson.withContext({
                        ...basicGunLesson.context,
                        itemKey: ItemKeyType.basicGun,
                        itemSettings: INTRO_SENTRY_SETTINGS,
                    }),
                },
                on: {
                    ANSWER_ENTERED: {
                        actions: forwardTo("basicGunLessonMachine"),
                    },
                    LESSON_EXIT_BUTTON_CLICKED: "sentryDeployed",
                },
            },
            sentryDeployed: {
                entry: [
                    `setSentryDeployedDialogue`,
                    `showFogOfWar`,
                    `spawnAlien`,
                    `spawnSentry`,
                ],
                // @ts-ignore
                invoke: {
                    id: `renderLoop`,
                    src: renderLoop,
                },
                on: {
                    FRAME: {
                        actions: [`broadcastToBoardPieces`],
                    },
                    FRAME_TIMER_TIC: {
                        actions: `frame`,
                    },
                    KILL_ALIEN: {
                        actions: `removeBoardPiece`,
                        target: `deployLocations`,
                    },
                },
            },
            deployLocations: {
                entry: [`setDeployLocationDialogue`],
                on: {
                    NEXT_BUTTON_CLICKED: {
                        actions: ["introComplete"],
                    },
                },
            },
        },
    },
    {
        actions: {
            broadcastToBoardPieces: pure((context, event) =>
                // @ts-ignore
                context.boardPieces.map((actor) => send(event, { to: actor }))
            ),
            clearAnswer: send(
                { type: "CLEAR_ANSWER" },
                { to: "answerMachine" }
            ),
            clearFogAroundControlRoom: send(
                () => {
                    const payload = {
                        visibleMapCoordsList: controlRoomCoordsList,
                    };

                    return { type: "FRAME", payload };
                },
                { to: "mapMachine" }
            ),
            clearFogAroundSonar: send(
                () => {
                    const coordsList = calcCoordsSquare({
                        centerCoords: INTRO_SONAR_SETTINGS.coords,
                        dimension: SONAR_RANGE_GRID_SQUARES,
                    });
                    const payload = {
                        visibleMapCoordsList: [
                            ...coordsList,
                            ...controlRoomCoordsList,
                        ],
                    };

                    return { type: "FRAME", payload };
                },
                { to: "mapMachine" }
            ),
            // clearHighlightSonarItem: sendParent("CLEAR_HIGHLIGHT_STOCK_BAR_ITEM"),
            clearMapHighlight: sendParent("CLEAR_MAP_HIGHLIGHT"),
            highlightControlRoom: sendParent({
                type: "HIGHLIGHT_MAP_COORDS",
                payload: controlRoomCoordsList,
            }),
            // highlightSonarItem: sendParent({
            //     type: "HIGHLIGHT_STOCK_BAR_ITEM",
            //     payload: ItemKeyType.sonar,
            // }),
            frame: send((context) => ({
                type: "FRAME",
                payload: {
                    // @ts-ignore
                    boardPieces: context.boardPieces,
                    frameCount: getFrameCount(),
                    visibleMapCoordsList: [
                        ...calcCoordsSquare({
                            centerCoords: INTRO_SONAR_SETTINGS.coords,
                            dimension: SONAR_RANGE_GRID_SQUARES,
                        }),
                        ...controlRoomCoordsList,
                    ],
                },
            })),
            setFacilityDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `The company has lost communications with 1 of its facilities.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setMarinesDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Your team of marines has been sent to investigate.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setAliensDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `You have discovered an alien infestation has overrun the facility.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setEvacDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `You have requested immediate evacuation. Evac craft on route.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setControlRoomDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Your team has secured the **main control room** in the facility.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setEquipmentDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Use **equipment** available to stop aliens reaching the control room.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            // -------------
            setSonarUnitDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `The **Sonar Unit** can detect alien movement.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setClickSonarDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Click the Sonar Unit to deploy.`,
                        isNextButton: false,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setDeploySonarDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Click the indicated location.`,
                        isNextButton: false,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setSonarDeployedDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `The sonar unit will detect movement in the surrounding area.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setAlienMovementDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `The sonar has detected an alien.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setStopAlienDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Stop it from reaching the control room!`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setClickSentryDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Select the sentry gun.`,
                        isNextButton: false,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setDeploySentryDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Click the indicated location.`,
                        isNextButton: false,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setSentryDeployedDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `When the alien moves within range, it will be destroyed.`,
                        isNextButton: false,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            setDeployLocationDialogue: send(
                {
                    type: "SET_DIALOGUE",
                    payload: {
                        dialogue: `Place your sentry guns wisely. If an alien reaches 1, it will destroy it.`,
                        isNextButton: true,
                    },
                },
                { to: `dialogueBoxMachine` }
            ),
            showMap: send("SHOW_MAP", { to: `mapMachine` }),
            showFogOfWar: send("SHOW_FOG_OF_WAR", { to: `mapMachine` }),
            // @ts-ignore
            spawnSentry: assign<IntroContextType>((context) => ({
                boardPieces: [
                    ...context.boardPieces,
                    spawn(
                        itemKeyMachineMap[ItemKeyType.basicGun](
                            INTRO_SENTRY_SETTINGS
                        )
                    ),
                ],
            })),
            hideFogOfWar: send("HIDE_FOG_OF_WAR", { to: `mapMachine` }),
            introComplete: sendParent("INTRO_COMPLETE"),
            // @ts-ignore
            spawnAlien: assign<IntroContextType>((context) => {
                return {
                    boardPieces: [
                        ...context.boardPieces,
                        spawn(alienMachine({ pathId: PathId.path1 }), uuidV4()),
                    ],
                };
            }),
            // @ts-ignore
            removeBoardPiece: assign((context: IntroContextType, event) => ({
                boardPieces: context.boardPieces.filter((actor) => {
                    // @ts-ignore
                    if (actor.id === event.payload) {
                        // @ts-ignore
                        actor.stop();
                        return false;
                    }

                    return true;
                }),
            })),
        },
        services: {
            answerMachine,
            dialogueBoxMachine,
            mapMachine,
            stockMachine,
        },
    }
);
