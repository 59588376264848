import { createMachine, assign } from "xstate";
import { MapEventType, MapStateType, MapContextType } from "./types";
import {
    FrameEventType,
    HighlightMapCoordsEventType,
} from "../gameMachine/types";

export const mapMachine = createMachine<
    MapContextType,
    MapEventType,
    // @ts-ignore
    MapStateType
>(
    {
        context: {
            highlightCoordsList: undefined,
            visibleCoordsList: [],
        },
        id: `map`,
        on: {
            CLEAR_MAP_HIGHLIGHT: {
                actions: `clearHighlightCoordsList`,
            },
            FRAME: {
                actions: `updateVisibleCoordsList`,
            },
            HIGHLIGHT_MAP_COORDS: {
                actions: `updateHighlightCoordsList`,
            },
        },
        type: "parallel",
        states: {
            image: {
                initial: `hide`,
                states: {
                    hide: {
                        on: {
                            SHOW_MAP: "show",
                        },
                    },
                    show: {
                        on: {
                            HIDE_MAP: "hide",
                        },
                    },
                },
            },
            fogOfWar: {
                initial: `hide`,
                states: {
                    hide: {
                        on: {
                            SHOW_FOG_OF_WAR: "show",
                        },
                    },
                    show: {
                        on: {
                            HIDE_FOG_OF_WAR: "hide",
                        },
                    },
                },
            },
        },
    },
    {
        actions: {
            clearHighlightCoordsList: assign<MapContextType>(() => ({
                highlightCoordsList: undefined,
            })),
            // @ts-ignore
            updateHighlightCoordsList: assign<
                MapContextType,
                HighlightMapCoordsEventType
            >((_, event) => ({
                highlightCoordsList: event.payload,
            })),
            // @ts-ignore
            updateVisibleCoordsList: assign<MapContextType, FrameEventType>(
                // @ts-ignore
                (_, event) => ({
                    visibleMapCoordsList: event.payload.visibleMapCoordsList,
                })
            ),
        },
    }
);
