import { ItemType, ItemKeyType } from "../types";
import { sonarLessonMachine } from "../machines/lessonMachines/sonarLessonMachine";
import { basicGunLessonMachine } from "../machines/lessonMachines/basicGunLessonMachine";
import sonarIcon from "../images/icons/sonarIcon.png";
import basicGunIcon from "../images/icons/basicGunIcon.png";

type ItemsObjectType = {
    [propName in ItemKeyType]: Omit<ItemType, "itemKey">;
};

export const itemsObject: ItemsObjectType = {
    [ItemKeyType.sonar]: {
        hasDirection: false,
        icon: sonarIcon,
        isUnlocked: true,
        lessonMachine: sonarLessonMachine,
        name: `Sonar Unit`,
        numberInStock: 8,
    },
    [ItemKeyType.basicGun]: {
        hasDirection: true,
        icon: basicGunIcon,
        isUnlocked: true,
        lessonMachine: basicGunLessonMachine,
        name: `Basic Sentry Gun`,
        numberInStock: 4,
    },
};

// Can't cast itemKey (don't know why)
// export const itemsArray = Object.keys(itemsObject).map((itemKey as NewItemsType) => ({
export const itemsArray: ItemType[] = Object.keys(itemsObject).map((itemKey) => ({
    // @ts-ignore
    ...itemsObject[itemKey],
    itemKey,
}));

export const allItemKeys = Object.keys(itemsObject);
