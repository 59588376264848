import { CoordsType } from "../types";

export const path3CoordsList: CoordsType[] = [
    [23, 29],
    [23, 28],
    [23, 27],

    [22, 27],
    [22, 26],
    [22, 25],
    [22, 24],
    [22, 23],
    [22, 22],
    [22, 21],
    [22, 20],

    [21, 20],
    [20, 20],
    [19, 20],
    [18, 20],
    [17, 19],

    [16, 18],
    [16, 17],
    [16, 16],
];
