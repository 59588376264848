import React, { CSSProperties, FC } from "react";
import { useSpring, animated, config } from "react-spring";
import { keyframes } from "styled-components";

export const mountFlickerAnimationKeyframes = keyframes`
    0%   { opacity: 0.5; }
    25%  { opacity: 0; }
    50%  { opacity: 0.75; }
    75%  { opacity: 0; }
    100% { opacity: 1; }
`;

// this is an estimate
export const MOUNT_FLICKER_ANIMATION_DURATION_SECONDS = 0.2;

const intervals = [0, 0.25, 0.5, 0.75, 1];
const opacity = [0.5, 0, 0.75, 0, 1];

interface PropsType {
    style?: CSSProperties;
}

export const MountFlickerAnimation: FC<PropsType> = ({ children, style: addedStyles }) => {
    const { animation } = useSpring({
        animation: 1,
        config: config.stiff,
        from: {
            animation: 0,
        },
    });

    const style = {
        ...addedStyles,
        opacity: animation
            .interpolate({
                range: intervals,
                output: opacity,
            })
            .interpolate((output) => output),
    } as CSSProperties;

    return <animated.div style={style}>{children}</animated.div>;
};
