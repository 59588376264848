import { CoordsType } from "../types";

export const path2CoordsList: CoordsType[] = [
    [-1, 3],
    [0, 3],
    [1, 3],
    [2, 3],
    [3, 3],
    [4, 3],
    [5, 3],
    [6, 3],
    [7, 3],
    [7, 4],
    [7, 5],
    [7, 6],
    [7, 7],
    [7, 8],
    [7, 9],
    [7, 10],
    [7, 11],

    [8, 12],

    [9, 13],
    [9, 14],
    [9, 15],
    [9, 16],
    [9, 17],
    [9, 18],

    [10, 18],
    [11, 18],
    [12, 18],

    [13, 18],
    [13, 17],
    [13, 16],
    [13, 15],
    [13, 14],

    [14, 14],
];
