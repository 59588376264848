export interface SvgProps {
    color?: string;
}

export type ParentProps = SvgProps & {
    iconKey: IconKeyType;
};

export enum IconKeyType {
    bottomLeftArrow = "bottomLeftArrow",
    bottomRightArrow = "bottomRightArrow",
    close = "close",
    downArrow = "downArrow",
    email = "email",
    leftArrow = "leftArrow",
    location = "location",
    menu = "menu",
    rightArrow = "rightArrow",
    topLeftArrow = "topLeftArrow",
    topRightArrow = "topRightArrow",
    twitter = "twitter",
    upArrow = "upArrow",
}
