import React, { FC } from "react";
import { MenuScreenLayout } from "./MenuScreenLayout";
import { Button } from "../Button";
import { H1 } from "../Typography/H1";
import { Paragraph } from "../Typography/Paragraph";
import { GridGutterRows, GridSquareRows } from "../Grid";

interface PropsType {
    onRestartClick: () => void;
}

export const GameOverScreen: FC<PropsType> = ({ onRestartClick }) => (
    <MenuScreenLayout>
        <H1>Game Over</H1>
        <GridGutterRows amount={3} />
        <Paragraph>Your team was killed.</Paragraph>
        <GridSquareRows amount={1} />
        <Button onClick={onRestartClick}>Restart</Button>
    </MenuScreenLayout>
);
