import React, { FC } from "react";
import { CoordsSquare } from "../../Map/CoordsSquare";
import { CoordsType, ItemKeyType } from "../../../types";
import styled from "styled-components";
import { itemsObject } from "../../../items";
import { gridGutters } from "../../../styles/grid";
import { MAP_SQUARE_DIMENSION } from "../../../coords";
import { RadiatingCircle } from "../../RadiatingCircle";
import { SONAR_RANGE_GRID_SQUARES } from "../../../machines/itemMachines/sonarMachine";

const Img = styled.img`
    width: 100%;
    height: 100%;

    padding: ${gridGutters(1)};
`;

const RadiatingCircleLayout = styled.div`
    width: ${MAP_SQUARE_DIMENSION * SONAR_RANGE_GRID_SQUARES}px;
    height: ${MAP_SQUARE_DIMENSION * SONAR_RANGE_GRID_SQUARES}px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    pointer-events: none;
`;

interface PropsType {
    coords: CoordsType;
}

export const Sonar: FC<PropsType> = ({ coords }) => (
    <CoordsSquare coords={coords} color="transparent">
        <Img src={itemsObject[ItemKeyType.sonar].icon} />
        <RadiatingCircleLayout>
            <RadiatingCircle />
        </RadiatingCircleLayout>
    </CoordsSquare>
);
