import React from "react";
import styled, { css } from "styled-components";
import { gridGutters } from "../../styles/grid";

export const StyledInput = styled.textarea`
    ${({ theme }) => css`
        width: 100%;
        max-width: ${theme.maxWidth.paragraph};
        padding: ${gridGutters(1)};

        color: ${theme.color.text[10]};
        border: 2px solid ${theme.color.clickable};
        background: transparent;
        resize: none;
    `}
`;

interface PropsType {
    onChange: (val: string) => void;
    value: string;
}

export const Input = ({ onChange, value }: PropsType) => (
    <StyledInput
        onChange={(event) => {
            onChange(event.target.value);
        }}
        rows={6}
        value={value}
        autoFocus
    />
);
