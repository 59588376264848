import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { paragraphStyles } from "./Paragraph";
import { gridGutters } from "../../styles/grid";
import { compiler } from "markdown-to-jsx";
import { WindupChildren, Pace, Effect } from "windups";
import { playTypingSound, stopTypingSound } from "../../sounds";

const Layout = styled.div`
    width: 100%;
    position: relative;
    display: flex;
`;

const MarkdownLayout = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    p:not(:first-child),
    ul:not(:first-child),
    li:not(:first-child) {
        margin-top: ${gridGutters(2)};
    }

    p {
        ${paragraphStyles};
    }

    ul {
        padding-left: ${gridGutters(2)};

        display: flex;
        flex-direction: column;
    }

    li {
        ${paragraphStyles};

        &:not(:first-child) {
            margin-top: ${gridGutters(1)};
        }

        ul {
            margin-top: ${gridGutters(1)};
            /* this is a fix due to sublist getting cut-off at the bottom (probably related to typography dimension transforms) */
            padding-bottom: ${gridGutters(1)};
        }
    }

    strong {
        font-weight: ${(p) => p.theme.fontWeight[1]};
        color: ${(p) => p.theme.color.text[8]};
    }
`;

const HeightSetter = styled(MarkdownLayout)`
    opacity: 0;
`;

const AnimatedMarkdownLayout = styled(MarkdownLayout)`
    position: absolute;
    top: 0;
    left: 0;
`;

interface PropsType {
    markdown: string;
}

export const Markdown: FC<PropsType> = ({ markdown }) => {
    useEffect(() => {
        return () => {
            stopTypingSound();
        };
    }, [markdown]);
    const renderedMarkdown = compiler(markdown);

    return (
        <Layout>
            <AnimatedMarkdownLayout>
                <WindupChildren onFinished={stopTypingSound}>
                    <Effect fn={playTypingSound} />
                    <Pace ms={5}>{renderedMarkdown}</Pace>
                </WindupChildren>
            </AnimatedMarkdownLayout>
            <HeightSetter aria-hidden={true}>{renderedMarkdown}</HeightSetter>
        </Layout>
    );
};
