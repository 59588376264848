import React, { FC } from "react";
import { useService } from "@xstate/react";
import { DialogueBox } from ".";
import { DialogueBoxActorType } from "../../machines/dialogueBoxMachine/types";

interface PropsType {
    actor: DialogueBoxActorType;
}

export const DialogueBoxWithActor: FC<PropsType> = ({ actor }) => {
    // @ts-ignore
    const [state, send] = useService(actor);
    // @ts-ignore
    const { dialogue } = state.context;
    const onNextClick = state.matches("nextButton.show") ? () => send("NEXT_BUTTON_CLICKED") : undefined;

    return dialogue ? <DialogueBox dialogueMarkdown={dialogue} onNextClick={onNextClick} /> : null;
};
