import React, { FC } from "react";
import { useService } from "@xstate/react";
import { Sonar } from ".";
import { SonarActorType } from "../../../machines/itemMachines/sonarMachine/types";

interface PropsType {
    actor: SonarActorType;
}

export const SonarWithActor: FC<PropsType> = ({ actor }) => {
    // @ts-ignore
    const [state] = useService(actor);
    const {
        coords,
        // scannedCoordsList
    } = state.context;

    return (
        <Sonar
            coords={coords}
            // scannedCoordsList={scannedCoordsList}
        />
    );
};
