import React, { useEffect } from "react";
import { AnswerInput, AnswerInputPropsType } from "../AnswerInput";
import { Button } from "../Button";
import { H1 } from "../Typography/H1";
import { H2 } from "../Typography/H2";
import {
    ScreenLayout,
    GridLayout,
    ExampleLayout,
    HeaderLayout,
    ExampleTemplateHeadingLayout,
    InputHeadingLayout,
    SystemsHeadingLayout,
    AnswerInputLayout,
    CompleteLayout,
    DialogueBoxLayout,
    SystemsLayout,
    System,
    Status,
    Icon,
} from "./Layouts";
import { Code } from "../Code";
import { LessonSystemsType } from "../../types";
import { DialogueBox } from "../DialogueBox";
import { LabelText } from "../Typography/LabelText";
import { scrollToTop } from "../../util/scrollToTop";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";
import { LookAtBlinkingAnimation } from "../Animations/BlinkingAnimation";
import { GridGutterColumns } from "../Grid";

interface PropsType {
    answer: string;
    cursorStartingPosition: AnswerInputPropsType["cursorStartingPosition"];
    example: string;
    heading: string;
    icon: string;
    instructionsMarkdown: string;
    isComplete: boolean;
    onChange: (value: string) => void;
    onExitClick: () => void;
    systems: LessonSystemsType;
}

export const LessonScreen = ({
    answer,
    cursorStartingPosition,
    example,
    heading,
    icon,
    instructionsMarkdown,
    isComplete,
    onChange,
    onExitClick,
    systems,
}: PropsType) => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <ScreenLayout>
            <DialogueBoxLayout>
                <DialogueBox dialogueMarkdown={instructionsMarkdown} />
            </DialogueBoxLayout>
            <GridLayout>
                <HeaderLayout>
                    <Icon src={icon} alt="" />
                    <H1>{heading}</H1>
                </HeaderLayout>

                <ExampleTemplateHeadingLayout>
                    <H2>Example Template</H2>
                </ExampleTemplateHeadingLayout>

                <ExampleLayout>
                    <Code>{example}</Code>
                </ExampleLayout>

                <InputHeadingLayout>
                    <H2>Input</H2>
                </InputHeadingLayout>

                <AnswerInputLayout>
                    <AnswerInput
                        cursorStartingPosition={cursorStartingPosition}
                        value={answer}
                        disabled={isComplete}
                        onChange={onChange}
                    />
                </AnswerInputLayout>

                <SystemsHeadingLayout>
                    <H2>Systems</H2>
                </SystemsHeadingLayout>

                <SystemsLayout>
                    {Object.keys(systems).map((name, i) => {
                        const isLast = i === Object.keys(systems).length - 1;

                        return (
                            <System key={name}>
                                <LabelText>{name}</LabelText>
                                <Status
                                    isInactive
                                    isColored={!systems[name]}
                                    isLast={isLast}
                                >
                                    <LabelText>non-operational</LabelText>
                                </Status>
                                <Status
                                    isColored={systems[name]}
                                    isLast={isLast}
                                >
                                    <LabelText>operational</LabelText>
                                </Status>
                            </System>
                        );
                    })}
                </SystemsLayout>
            </GridLayout>
            {isComplete && (
                <MountFlickerAnimation>
                    <CompleteLayout>
                        <LookAtBlinkingAnimation>
                            <H2>{heading} Operational</H2>
                        </LookAtBlinkingAnimation>
                        <GridGutterColumns amount={2} />
                        <Button onClick={onExitClick}>exit</Button>
                    </CompleteLayout>
                </MountFlickerAnimation>
            )}
        </ScreenLayout>
    );
};
