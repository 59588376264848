import React from "react";
import { useService } from "@xstate/react";
import { StockActorType } from "../../machines/stockMachine/types";
import { StockBar } from ".";
import { ItemType } from "../../types";
import { ButtonGrid } from "../ButtonGrid";
import { calcDirection } from "../../util/calcDirection";
import { DirectionButtonGrid } from "../DirectionButtonGrid";

interface PropsType {
    actor: StockActorType;
}

export const StockBarWithActor = ({ actor }: PropsType) => {
    // @ts-ignore
    const [state, send] = useService(actor);
    // @ts-ignore
    const {
        directionButtonCoords,
        deployGridCoordsList,
        highlightItemKey,
        items,
        selectedItemSettings,
    } = state.context;
    const unlockedItems = items.filter((item: ItemType) => item.isUnlocked);

    if (state.matches("selectingItem")) {
        return (
            <StockBar
                highlightItemKey={highlightItemKey}
                items={unlockedItems}
                onItemClick={(itemKey) => send({ type: "ITEM_BUTTON_CLICKED", payload: itemKey })}
            />
        );
    }

    if (state.matches("selectingItemCoords")) {
        return (
            <ButtonGrid
                buttonCoords={deployGridCoordsList}
                onButtonClick={(coords) =>
                    send({
                        type: "COORD_BUTTON_CLICKED",
                        payload: coords,
                    })
                }
            />
        );
    }

    if (state.matches("selectingItemDirection")) {
        return (
            <DirectionButtonGrid
                buttonCoords={directionButtonCoords}
                itemCoords={selectedItemSettings.coords}
                onButtonClick={(coords) =>
                    send({
                        type: "DIRECTION_BUTTON_CLICKED",
                        payload: calcDirection({
                            originCoords: selectedItemSettings.coords,
                            targetCoords: coords,
                        }),
                    })
                }
            />
        );
    }

    return null;
};
