import { createMachine, sendParent, assign } from "xstate";
import { ExtractionEventType, ExtractionStateType, ExtractionContextType } from "./types";
import { extractionClock } from "../../callbacks/extractionClock";
import { TIME_TO_EXTRACTION_STARTING_TIME_SECONDS } from "../../constants";

export const extractionMachine = createMachine<ExtractionContextType, ExtractionEventType, ExtractionStateType>(
    {
        context: {
            timeToExtractionSeconds: TIME_TO_EXTRACTION_STARTING_TIME_SECONDS,
        },
        id: `extraction`,
        initial: `countingDown`,
        states: {
            countingDown: {
                // I don't understand how this work, when the machine transitions to the determining state, the extraciton clock should unmount
                invoke: {
                    src: `extractionClock`,
                },
                on: {
                    EXTRACTION_CLOCK_TIC: {
                        actions: "minusCount",
                        target: "determining",
                    },
                },
            },
            determining: {
                always: [
                    {
                        cond: `isAt0`,
                        target: `complete`,
                    },
                    {
                        target: "countingDown",
                    },
                ],
            },
            complete: {
                entry: `broadcastComplete`,
                type: "final",
            },
        },
    },
    {
        actions: {
            minusCount: assign({
                timeToExtractionSeconds: (context, event) => context.timeToExtractionSeconds - 1,
            }),
            broadcastComplete: sendParent("TIME_TO_EXTRACTION_COMPLETE"),
        },
        guards: {
            isAt0: (context) => context.timeToExtractionSeconds === 0,
        },
        services: {
            // @ts-ignore
            extractionClock,
        },
    }
);
