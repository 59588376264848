import { CoordsType } from "../types";
import { MAP_COL_COUNT } from ".";
import { times } from "lodash";

const columnCoords = times(MAP_COL_COUNT);
const rowCoords = (rowIndex: number) => columnCoords.map((col) => [col, rowIndex]) as CoordsType[];

const excludeX = (row: CoordsType[], xValues: number[]) => row.filter(([x, y]) => !xValues.includes(x));
const includeX = (row: CoordsType[], xValues: number[]) => row.filter(([x, y]) => xValues.includes(x));

export const playableCoordsList: CoordsType[] = [
    ...includeX(rowCoords(0), [7]),
    ...includeX(rowCoords(1), [7]),
    ...includeX(rowCoords(2), [7]),
    ...includeX(rowCoords(3), [0, 1, 2, 3, 4, 5, 6, 7]),
    ...includeX(rowCoords(4), [7]),
    ...includeX(rowCoords(5), [7, 22, 23, 24, 25, 26, 27, 28]),
    ...includeX(rowCoords(6), [7, 21, 29]),
    ...includeX(rowCoords(7), [7, 21, 23, 24, 25, 26, 27, 29]),
    ...includeX(rowCoords(8), [7, 21, 23, 24, 25, 26, 27, 29, 30, 31, 32]),
    ...includeX(rowCoords(9), [7, 21, 23, 24, 25, 26, 27, 29]),
    ...includeX(rowCoords(10), [7, 21, 29]),
    ...excludeX(rowCoords(11), [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 20, 21, 30, 31, 32]),
    ...excludeX(rowCoords(12), [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        9,
        10,
        11,
        12,
        14,
        15,
        16,
        17,
        18,
        20,
        21,
        22,
        23,
        27,
        28,
        29,
        30,
        31,
        32,
    ]),
    ...excludeX(rowCoords(13), [7, 8, 10, 11, 12, 14, 15, 16, 17, 18, 21, 27, 28, 29, 30, 31, 32]),
    ...excludeX(rowCoords(14), [0, 8, 10, 11, 12, 14, 15, 16, 17, 18, 21, 27, 28, 29, 30, 31, 32]),
    ...excludeX(rowCoords(15), [8, 10, 11, 12, 14, 15, 16, 17, 18, 21, 31, 32]),
    ...excludeX(rowCoords(16), [0, 8, 10, 11, 12, 14, 15, 16, 17, 18, 21, 23, 24, 25, 26, 27, 28, 29, 31, 32]),
    ...excludeX(rowCoords(17), [0, 1, 2, 3, 4, 5, 6, 8, 10, 11, 12, 21, 23, 29, 31, 32]),
    ...excludeX(rowCoords(18), [0, 23, 29, 31, 32]),
    ...includeX(rowCoords(19), [3, 4, 5, 6, 7, 9, 10, 11, 13, 17, 18, 19, 20, 21, 22, 30]),
    ...includeX(rowCoords(20), [3, 4, 5, 6, 9, 10, 11, 13, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]),
    ...includeX(rowCoords(21), [3, 4, 6, 7, 8, 9, 10, 11, 13, 15, 18, 22, 25, 26, 27, 28]),
    ...includeX(rowCoords(22), [3, 6, 7, 8, 9, 10, 11, 13, 14, 18, 22, 26, 27, 28]),
    ...includeX(rowCoords(23), [1, 2, 3, 13, 14, 15, 16, 17, 18, 22, 26, 27, 28]),
    ...excludeX(rowCoords(24), [0, 18, 19, 20, 21, 31, 32]),
    ...includeX(rowCoords(25), [1, 7, 22, 26, 31]),
    ...includeX(rowCoords(26), [1, 7, 22, 26, 31]),
    ...excludeX(rowCoords(27), [0, 31, 32]),
    ...includeX(rowCoords(28), [3, 9, 17, 23, 28]),
];
