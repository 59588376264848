import React from "react";
import styled from "styled-components";
import { MAP_HEIGHT, MAP_WIDTH } from "../../coords";
import { CoordsType } from "../../types";
import { Button } from "./Button";
import { Icon } from "../Icon";
import { calcDirection } from "../../util/calcDirection";
import { directionToIconMap } from "../../util/directionToIconMap";
import { theme } from "../../styles/theme";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";

const Layout = styled.section`
    width: ${MAP_WIDTH}px;
    height: ${MAP_HEIGHT}px;

    position: absolute;
    top: 0;
    left: 0;
`;

interface PropsType {
    buttonCoords: CoordsType[];
    itemCoords: CoordsType;
    onButtonClick: (coords: CoordsType) => void;
}

export const DirectionButtonGrid = ({ buttonCoords, itemCoords, onButtonClick }: PropsType) => (
    <Layout>
        <MountFlickerAnimation style={{ width: `100%`, height: `100%`, position: `relative` }}>
            {buttonCoords.map((coords) => {
                const direction = calcDirection({ originCoords: itemCoords, targetCoords: coords });
                const iconKey = directionToIconMap[direction];

                return (
                    <Button
                        key={coords.toString()}
                        coords={coords}
                        onClick={() => {
                            onButtonClick(coords);
                        }}
                    >
                        <Icon iconKey={iconKey} color={theme.color.clickable[0]} />
                    </Button>
                );
            })}
        </MountFlickerAnimation>
    </Layout>
);
