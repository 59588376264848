import { createMachine } from "xstate";
import { RadarPingEventType, RadarPingStateType } from "./types";
import { FrameEventType } from "../gameMachine/types";
import { getAlienPieces } from "../../util/filterBoardPieces";
import { isMoveFrame } from "../../util/isMoveFrame";
import { isEqual } from "lodash";
import { playRadarPingSound } from "../../sounds";

export const radarPingMachine = createMachine<
    null,
    RadarPingEventType,
    RadarPingStateType
>(
    {
        id: `radarPing`,
        initial: `active`,
        states: {
            active: {
                on: {
                    FRAME: [
                        {
                            cond: `isMoveFrameAndVisibleAlien`,
                            actions: `makePingSound`,
                        },
                    ],
                },
            },
        },
    },
    {
        actions: {
            makePingSound: (_, event: FrameEventType) => {
                playRadarPingSound();
            },
        },
        guards: {
            isMoveFrameAndVisibleAlien: (_, event: FrameEventType) => {
                const { boardPieces, frameCount } = event.payload;
                const aliens = getAlienPieces(boardPieces);

                if (!isMoveFrame(frameCount)) {
                    return false;
                }

                return aliens.some((alien) =>
                    isEqual(alien.state.value, { moving: "visible" })
                );
            },
        },
    }
);
