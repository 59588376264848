import React, { useState, FormEvent } from "react";
import { Input } from "../Input";
import styled from "styled-components";
import { Button } from "../Button";
import { GridGutterRows } from "../Grid";
import emailjs from "emailjs-com";
import {
    EMAIL_JS_USER_ID,
    EMAIL_JS_SERVICE_ID,
    EMAIL_JS_TEMPLATE_ID,
} from "../../constants/emailJS";
import { Paragraph } from "../Typography/Paragraph";

const Form = styled.form`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const FeebackForm = () => {
    const [value, setValue] = useState("");
    const [isComplete, setComplete] = useState(false);
    const onChange = (newValue: string) => setValue(newValue);
    const onSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (value.length > 0) {
            emailjs
                .send(
                    EMAIL_JS_SERVICE_ID,
                    EMAIL_JS_TEMPLATE_ID,
                    {
                        feedback: value,
                    },
                    EMAIL_JS_USER_ID
                )
                .then(
                    (result) => {
                        setComplete(true);
                    },
                    (error) => {
                        setComplete(true);
                    }
                );
        }
    };

    return isComplete ? (
        <Paragraph>Thank you for your feedback.</Paragraph>
    ) : (
        <Form>
            <Input onChange={onChange} value={value} />
            <GridGutterRows />
            <Button onClick={onSubmit}>Send Feedback</Button>
        </Form>
    );
};
