import React, { FC, CSSProperties, useState } from "react";
import { useSpring, animated } from "react-spring";
import { RENDER_LOOP_SECONDS, ALIEN_SPEED_FRAMES } from "../../constants";

const intervals = [0, 0.25, 0.5, 0.75, 1];
const opacity = [1, 0.75, 0.5, 0.25, 0];

export const SonarFadeAnimation: FC = ({ children }) => {
    const [animationState, setAnimationState] = useState(false);
    const startAnimation = () => setAnimationState(true);

    const { animation } = useSpring({
        animation: 1,
        config: {
            duration: ALIEN_SPEED_FRAMES * RENDER_LOOP_SECONDS * 1000,
        },
        from: {
            animation: 0,
        },
        onRest: () => {
            setAnimationState(false);
            setTimeout(startAnimation);
        },
        reset: animationState,
    });

    const style = {
        opacity: animation
            .interpolate({
                range: intervals,
                output: opacity,
            })
            .interpolate((output) => output),
    } as CSSProperties;

    return <animated.div style={style}>{children}</animated.div>;
};
