import styled, { css } from "styled-components";

const movingScanline = css`
    &:before {
        display: block;
        pointer-events: none;
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        z-index: 1;
        background: hsla(0, 0%, 0%, 0.5);
        animation: scanline 6s linear infinite;

        @keyframes scanline {
            0% {
                transform: translate3d(0, 200000%, 0);
            }
        }
    }
`;

export const ScanLines = styled.div`
    width: 100%;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    /* required by moving scanline */
    overflow: hidden;

    background: repeating-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35) 1px,
        transparent 1px,
        transparent 2px
    );
    pointer-events: none;

    ${movingScanline}
`;
