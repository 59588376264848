import React, { FC } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Layout } from "./Layout";
import "codemirror/mode/javascript/javascript";
import "codemirror/lib/codemirror.css";
// theme preview: https://codemirror.net/demo/theme.html#ambiance
// import "codemirror/theme/material.css";
import "./oneDarkTheme.css";

// not sure how to get this to work
// import "codemirror/addon/hint/javascript-hint";

export interface AnswerInputPropsType {
    autoFocus?: boolean;
    cursorStartingPosition?: {
        line: number;
        ch: number;
    };
    disabled?: boolean;
    mode?: string;
    onChange: (value: string) => void;
    value: string;
}

export const AnswerInput: FC<AnswerInputPropsType> = ({
    autoFocus = true,
    cursorStartingPosition,
    disabled = false,
    mode = "javascript",
    onChange,
    value,
}) => {
    // https://codemirror.net/doc/manual.html#config
    const options = {
        autofocus: autoFocus && !disabled,
        lineNumbers: true,
        mode,
        readOnly: disabled ? "nocursor" : false,
        // theme: "material",
        theme: "one-dark",
    };

    return (
        <Layout disabled={disabled}>
            <CodeMirror
                value={value}
                options={options}
                cursor={cursorStartingPosition}
                onBeforeChange={(editor, data, value) => {
                    onChange(value);
                }}
            />
        </Layout>
    );
};
