import { spawn, send } from "xstate";
import {
    DestroyGunEventType,
    KillAlienEventType,
    GameContextType,
    SpawnAliensEventType,
    FrameTimerTicEventType,
    DeployCompleteEventType,
    LessonCompleteEventType,
    ClearFogEventType,
    BoardPieceActorType,
} from "./types";
import { renderLoop } from "../../callbacks/renderLoop";
import { log, assign, pure, forwardTo } from "xstate/lib/actions";
import { extractionMachine } from "../extractionMachine";
import { queenMachine } from "../queenMachine";
import { alienMachine } from "../alienMachine";
import { dialogueBoxMachine } from "../dialogueBoxMachine";
import { stockMachine } from "../stockMachine";
import { answerMachine } from "../answerMachine";
import { radarPingMachine } from "../radarPingMachine";
import { introMachine } from "../introMachine";
import { notificationsMachine } from "../notificationsMachine";
import { v4 as uuidV4 } from "uuid";
import { itemKeyMachineMap } from "../itemMachines";
import { itemsObject } from "../../items";
import { isEqual, uniqWith } from "lodash";
import { calcDirectionCoords } from "../../util/calcDirectionCoords";
import { initialContext } from "./initialContext";
import { mapMachine } from "../mapMachine";
import { ItemKeyType } from "../../types";
import { INTRO_SONAR_SETTINGS, INTRO_SENTRY_SETTINGS } from "../../constants";

export const options = {
    actions: {
        broadcastToBoardPieces: pure((context, event) =>
            // @ts-ignore
            context.boardPieces.map((actor) => send(event, { to: actor }))
        ),
        // @ts-ignore
        clearFogAroundItem: send((_, event: LessonCompleteEventType) => {
            const { coords, direction } = event.payload.itemSettings;
            const payload = [coords];

            if (direction) {
                payload.push(calcDirectionCoords({ coords, direction }));
            }

            return { type: "CLEAR_FOG", payload };
        }),
        // @ts-ignore
        resetContext: assign<GameContextType>(() => ({
            ...initialContext,
        })),
        // @ts-ignore
        forwardToDialogueBox: forwardTo("dialogueBoxMachine"),
        // @ts-ignore
        forwardToMap: forwardTo("mapMachine"),
        // @ts-ignore
        forwardToSelectedLesson: forwardTo((context) => context.selectedLesson),
        // @ts-ignore
        frame: send(
            (context: GameContextType, event: FrameTimerTicEventType) => ({
                type: "FRAME",
                payload: {
                    boardPieces: context.boardPieces,
                    frameCount: event.payload,
                    visibleMapCoordsList: context.visibleMapCoordsList,
                },
            })
        ),
        log: log(
            (_, event) =>
                `gameMachine - event: ${event.type}${
                    // @ts-ignore
                    event.payload ? ` payload: ${event.payload}` : ``
                }`
        ),
        notifyUserGunDestroyed: send(
            { type: `NOTIFY_USER`, payload: `Basic sentry gun destroyed` },
            { to: "notificationsMachine" }
        ),
        // @ts-ignore
        spawnAliens: assign<GameContextType, SpawnAliensEventType>(
            // @ts-ignore
            (context, event) => {
                const { amount, pathId } = event.payload;
                const newAliens = Array.from({ length: amount }).map(() =>
                    spawn(alienMachine({ pathId }), uuidV4())
                );

                return {
                    boardPieces: [...context.boardPieces, ...newAliens],
                };
            }
        ),
        // @ts-ignore
        spawnStartingItems: assign<GameContextType>((context) => ({
            boardPieces: [
                ...context.boardPieces,
                spawn(
                    itemKeyMachineMap[ItemKeyType.sonar](INTRO_SONAR_SETTINGS)
                ),
                spawn(
                    itemKeyMachineMap[ItemKeyType.basicGun](
                        INTRO_SENTRY_SETTINGS
                    )
                ),
            ],
        })),
        // @ts-ignore
        spawnItem: assign<GameContextType, LessonCompleteEventType>(
            // @ts-ignore
            (context, event) => ({
                boardPieces: [
                    ...context.boardPieces,
                    spawn(
                        itemKeyMachineMap[event.payload.itemKey](
                            event.payload.itemSettings
                        )
                    ),
                ],
            })
        ),
        // @ts-ignore
        spawnLesson: assign<GameContextType, DeployCompleteEventType>(
            // @ts-ignore
            (_, event) => {
                const { itemSettings, itemKey } = event.payload;
                const lessonMachine = itemsObject[itemKey].lessonMachine;

                return {
                    selectedLesson: spawn(
                        lessonMachine.withContext({
                            ...lessonMachine.context,
                            itemKey,
                            itemSettings,
                        })
                    ),
                };
            }
        ),
        showFogOfWar: send("SHOW_FOG_OF_WAR", { to: `mapMachine` }),
        showMap: send("SHOW_MAP", { to: `mapMachine` }),
        stopBoardPieces: (context: GameContextType) => {
            context.boardPieces.forEach((actor) => {
                // @ts-ignore
                actor.stop();
            });
        },
        // @ts-ignore
        removeBoardPiece: assign<
            GameContextType,
            DestroyGunEventType | KillAlienEventType
        >((context, event) => ({
            boardPieces: context.boardPieces.filter((actor) => {
                if (actor.id === event.payload) {
                    // @ts-ignore
                    actor.stop();
                    return false;
                }

                return true;
            }),
        })),
        // @ts-ignore
        removeLesson: assign<GameContextType>((context) => {
            // @ts-ignore
            context.selectedLesson?.stop();

            return {
                selectedLesson: undefined,
            };
        }),
        // @ts-ignore
        updateVisibleCoordsList: assign<GameContextType, ClearFogEventType>(
            (context, event) => ({
                visibleMapCoordsList: uniqWith(
                    [...context.visibleMapCoordsList, ...event.payload],
                    isEqual
                ),
            })
        ),
    },
    guards: {
        isBoardPieceAvailable: (
            context: GameContextType,
            event: DestroyGunEventType
        ) =>
            context.boardPieces.some(
                (actor: BoardPieceActorType) => actor.id === event.payload
            ),
    },
    services: {
        answerMachine,
        dialogueBoxMachine,
        extractionMachine,
        introMachine,
        mapMachine,
        notificationsMachine,
        queenMachine,
        radarPingMachine,
        // @ts-ignore
        renderLoop,
        stockMachine,
    },
};
