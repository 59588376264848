import { Sender } from "xstate";
import { FrameTimerTicEventType, GameContextType, GameEventType } from "../machines/gameMachine/types";
import { RENDER_LOOP_SECONDS } from "../constants";

export const renderLoop = (context: GameContextType, event: GameEventType) => (
    callback: Sender<FrameTimerTicEventType>
) => {
    let count = 0;

    const intervalId = setInterval(() => {
        callback({ type: "FRAME_TIMER_TIC", payload: count });
        count++;
    }, RENDER_LOOP_SECONDS * 1000);

    return () => {
        clearInterval(intervalId);
    };
};
