import React from "react";
import { useService } from "@xstate/react";
import { Ul } from "./Components";
import { NotificationsActorType } from "../../machines/notificationsMachine/types";
import { Notification } from "./Notification";
import { NotificationActorType } from "../../machines/notificationMachine/types";

interface PropsType {
    actor: NotificationsActorType;
}

export const Notifications = ({ actor }: PropsType) => {
    // @ts-ignore
    const [state] = useService(actor);
    // @ts-ignore
    const { notifications } = state.context;

    return notifications.length > 0 ? (
        <Ul>
            {notifications.map((actor: NotificationActorType) => (
                <Notification key={actor.id} actor={actor} />
            ))}
        </Ul>
    ) : null;
};
