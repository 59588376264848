import React, { FC } from "react";
import styled, { css } from "styled-components";
import { gridGutters } from "../../styles/grid";
import { ButtonText } from "../Typography/ButtonText";
import { playbuttonClickSound } from "../../sounds";

interface StyledButtonPropsType {
    disabled?: boolean;
    hidden?: boolean;
}

type ButtonPropsType = StyledButtonPropsType & {
    children: string;
    onClick: (event: any) => void;
};

export const StyledButton = styled.button<StyledButtonPropsType>`
    ${({ theme, hidden, disabled }) => css`
        height: ${gridGutters(5)};

        padding: 0 ${gridGutters(3)};
        display: flex;
        align-items: center;
        font-size: ${theme.fontSize[0]};

        text-transform: uppercase;
        letter-spacing: 0.08em;
        border-radius: ${theme.borderRadius};
        border: ${theme.buttonBorder};
        background: ${theme.color.background[0]};

        :hover {
            background: ${theme.color.hover};
        }

        ${() =>
            hidden &&
            css`
                pointer-events: none;
                opacity: 0;
            `}

        ${() =>
            disabled &&
            css`
                pointer-events: none;
                opacity: ${theme.opacity.disabled};
            `}
    `}
`;

export const Button: FC<ButtonPropsType> = ({ children, onClick, ...rest }) => (
    <StyledButton
        {...rest}
        onClick={(event) => {
            playbuttonClickSound();
            onClick(event);
        }}
    >
        <ButtonText>{children}</ButtonText>
    </StyledButton>
);

type LinkPropsType = StyledButtonPropsType & {
    href: string;
};

export const Link: FC<LinkPropsType> = (props) => (
    <StyledButton as="a" {...props} />
);
