import React, { FC } from "react";
import styled from "styled-components";
import { MAP_HEIGHT, MAP_WIDTH } from "../../coords";
import { CoordsType } from "../../types";
import { Button } from "./Button";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";

const Layout = styled.section`
    width: ${MAP_WIDTH}px;
    height: ${MAP_HEIGHT}px;

    position: absolute;
    top: 0;
    left: 0;
`;

interface PropsType {
    buttonCoords: CoordsType[];
    onButtonClick: (coords: CoordsType) => void;
}

export const ButtonGrid: FC<PropsType> = ({ buttonCoords, onButtonClick }) => (
    <Layout>
        <MountFlickerAnimation style={{ width: `100%`, height: `100%`, position: `relative` }}>
            {buttonCoords.map((coords) => (
                <Button key={coords.toString()} coords={coords} onClick={() => onButtonClick(coords)} />
            ))}
        </MountFlickerAnimation>
    </Layout>
);
