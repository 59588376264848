import { createMachine, assign, spawn } from "xstate";
import {
    NotificationsEventType,
    NotificationsStateType,
    NotificationsContextType,
} from "./types";
import {
    NotifyUserEventType,
    RemoveNotificationEventType,
} from "../gameMachine/types";
import { notificationMachine } from "../notificationMachine";
import { v4 as uuidV4 } from "uuid";

export const notificationsMachine = createMachine<
    NotificationsContextType,
    NotificationsEventType,
    NotificationsStateType
>(
    {
        id: `notifications`,
        context: {
            notifications: [],
        },
        initial: `active`,
        states: {
            active: {
                on: {
                    NOTIFY_USER: {
                        actions: `spawnNotification`,
                    },
                    REMOVE_NOTIFICATION: {
                        actions: `unspawnNotification`,
                    },
                },
            },
        },
    },
    {
        actions: {
            // @ts-ignore
            spawnNotification: assign<
                NotificationsContextType,
                NotifyUserEventType
            >(
                // @ts-ignore
                (context, event) => {
                    const id = uuidV4();

                    return {
                        notifications: [
                            ...context.notifications,
                            spawn(
                                notificationMachine({
                                    text: event.payload,
                                    id,
                                }),
                                id
                            ),
                        ],
                    };
                }
            ),
            // @ts-ignore
            unspawnNotification: assign<
                NotificationsContextType,
                RemoveNotificationEventType
            >((context, event) => {
                return {
                    notifications: context.notifications.filter((actor) => {
                        if (actor.id === event.payload) {
                            // @ts-ignore
                            actor.stop();
                            return false;
                        }

                        return true;
                    }),
                };
            }),
        },
    }
);
