import { createMachine, sendParent } from "xstate";
import { SonarContextType, SonarStateType } from "./types";
import { BoardPieceKeyType, ItemSettingsType } from "../../../types";
import { calcCoordsSquare } from "../../../util/calcCoordsSquare";

export const SONAR_RANGE_GRID_SQUARES = 7;

export const sonarMachine = (itemSettings: ItemSettingsType) => {
    const { coords } = itemSettings;
    const scannedCoordsList = calcCoordsSquare({
        centerCoords: coords,
        dimension: SONAR_RANGE_GRID_SQUARES,
    });

    return createMachine<SonarContextType, any, SonarStateType>(
        {
            context: {
                coords,
                scannedCoordsList,
            },
            id: BoardPieceKeyType.sonar,
            initial: `active`,
            meta: {
                // isNonBlocking: true,
            },
            states: {
                active: {
                    entry: `clearFog`,
                },
            },
        },
        {
            actions: {
                clearFog: sendParent({
                    type: "CLEAR_FOG",
                    payload: scannedCoordsList,
                }),
            },
        }
    );
};
