import React, { FC, memo } from "react";
import styled from "styled-components";
import { CoordsSquare } from "../Map/CoordsSquare";
import { theme } from "../../styles/theme";
import { CoordsType } from "../../types";
import { differenceWith, isEqual } from "lodash";
import { mapCoordsList } from "../../coords";

const Fill = styled.div`
    width: 100%;
    height: 100%;

    background: ${(p) => p.theme.color.fogOfWar};
`;

interface PropsType {
    visibleMapCoordsList: CoordsType[];
}

export const FogOfWar: FC<PropsType> = memo(({ visibleMapCoordsList }) => {
    const hiddenCoordsList = differenceWith(
        mapCoordsList,
        visibleMapCoordsList,
        isEqual
    );

    return (
        <>
            {hiddenCoordsList.map((coords) => (
                <CoordsSquare
                    key={coords.toString()}
                    coords={coords}
                    color={theme.color.fogOfWar}
                >
                    <Fill />
                </CoordsSquare>
            ))}
        </>
    );
});
