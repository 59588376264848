import { gridGutters } from "../../styles/grid";
import styled from "styled-components";

export const Ul = styled.ul`
    position: fixed;
    top: ${gridGutters(1)};
    left: ${gridGutters(1)};

    display: flex;
    flex-direction: column;

    pointer-events: none;

    > :not(:first-child) {
        margin-top: ${gridGutters(1)};
    }
`;

export const Li = styled.li`
    padding: ${gridGutters(1)};

    background: ${(p) => p.theme.color.background[0]};
    border: ${(p) => p.theme.border};
`;
