import { CoordsType, DirectionType } from "../types";

interface PropsType {
    targetCoords: CoordsType;
    originCoords: CoordsType;
}

export const calcDirection = ({ originCoords, targetCoords }: PropsType) => {
    const [originX, originY] = originCoords;
    const [targetX, targetY] = targetCoords;

    if (originY > targetY && originX < targetX) {
        return DirectionType.UpRight;
    }

    if (originY < targetY && originX < targetX) {
        return DirectionType.DownRight;
    }

    if (originY < targetY && originX > targetX) {
        return DirectionType.DownLeft;
    }

    if (originY > targetY && originX > targetX) {
        return DirectionType.UpLeft;
    }

    if (originY > targetY) {
        return DirectionType.Up;
    }

    if (originX < targetX) {
        return DirectionType.Right;
    }

    if (originY < targetY) {
        return DirectionType.Down;
    }

    return DirectionType.Left;
};
