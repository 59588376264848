import { Howl } from "howler";
import typingSoundMp3 from "./typingSound.mp3";
import radarPingSoundMp3 from "./radarPingPhoneSound.mp3";
import buttonClickSoundMp3 from "./buttonClickSound.mp3";
import warningSoundMp3 from "./warningSound.mp3";
import mapHighlightSoundMp3 from "./mapHighlightSound.mp3";
import basicGunFiringSoundMp3 from "./basicGunFiringSound.mp3";
import mountingSoundMp3 from "./mountingSound.mp3";

const typingSound = new Howl({
    src: [typingSoundMp3],
    loop: true,
});

const radarPingSound = new Howl({
    src: [radarPingSoundMp3],
});

const buttonClickSound = new Howl({
    src: [buttonClickSoundMp3],
});

const warningSound = new Howl({
    src: [warningSoundMp3],
});

const mapHighlightSound = new Howl({
    src: [mapHighlightSoundMp3],
});

const basicGunFiringSound = new Howl({
    src: [basicGunFiringSoundMp3],
    volume: 0.1,
});

const mountingSound = new Howl({
    src: [mountingSoundMp3],
    volume: 0.1,
});

export const playTypingSound = () => typingSound.play();
export const playRadarPingSound = () => radarPingSound.play();
export const playbuttonClickSound = () => buttonClickSound.play();
export const playWarningSound = () => warningSound.play();
export const playMapHighlightSound = () => mapHighlightSound.play();
export const playBasicGunFiringSound = () => basicGunFiringSound.play();
export const playMountingSound = () => mountingSound.play();

export const stopTypingSound = () => typingSound.stop();
