import { ItemKeyType, CoordsType } from "../types";
import { BoardPieceActorType } from "../machines/gameMachine/types";
import { itemsObject } from "../items";
import { getItemPieces, getBlockingItemPieces } from "./filterBoardPieces";
import { getBoardPieceCoords } from "./getBoardPieceCoords";
import { differenceWith, isEqual, flatMap } from "lodash";
import { calcCoordsSquare } from "./calcCoordsSquare";
import { playableCoordsList } from "../coords/playableCoordsList";

const calcWithoutDirection = (boardPieces: BoardPieceActorType[]): CoordsType[] => {
    const itemPieces = getItemPieces(boardPieces);
    const allItemCoordsList = itemPieces.map((piece) => getBoardPieceCoords(piece));

    return differenceWith(playableCoordsList, allItemCoordsList, isEqual);
};

// TODO - add range argument and change dimension to range)
const calcWithDirection = (boardPieces: BoardPieceActorType[]): CoordsType[] => {
    const blockingItemPieces = getBlockingItemPieces(boardPieces);
    const exemptCoords = flatMap(blockingItemPieces, (piece) =>
        calcCoordsSquare({
            centerCoords: getBoardPieceCoords(piece),
            dimension: 3,
        })
    );

    return differenceWith(playableCoordsList, exemptCoords, isEqual);
};

interface PropsType {
    itemKey: ItemKeyType;
    boardPieces: BoardPieceActorType[];
}

export const calcDeployGridCoordsList = ({ itemKey, boardPieces }: PropsType): CoordsType[] => {
    const item = itemsObject[itemKey];
    const { hasDirection } = item;

    if (hasDirection) {
        return calcWithDirection(boardPieces);
    }

    return calcWithoutDirection(boardPieces);
};
