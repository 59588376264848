import { Sender } from "xstate";
import { ExtractionClockTicEventType, GameContextType, GameEventType } from "../machines/gameMachine/types";

export const extractionClock = (context: GameContextType, event: GameEventType) => (
    callback: Sender<ExtractionClockTicEventType>
) => {
    const intervalId = setInterval(() => {
        callback({ type: "EXTRACTION_CLOCK_TIC" });
    }, 1000);

    return () => {
        clearInterval(intervalId);
    };
};
