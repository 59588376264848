import React, { FC } from "react";
import styled from "styled-components";
import { H3 } from "../Typography/H3";
import { H4 } from "../Typography/H4";
import { GridGutterRows } from "../Grid";
import { secondsToHoursMinutesSeconds } from "../../util/secondsToHoursMinutesSeconds";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";
import { TIME_TO_EXTRACTION_STARTING_TIME_SECONDS } from "../../constants";

const Layout = styled.section`
    display: flex;
    flex-direction: column;
`;

const Spacing = styled.span`
    letter-spacing: 0.1em;
`;

interface PropsType {
    seconds: number;
}

export const Timer: FC<PropsType> = ({ seconds }) => {
    const component = (
        <Layout>
            <H4>Time to Extraction</H4>
            <GridGutterRows amount={2} />
            <H3>
                <Spacing>{secondsToHoursMinutesSeconds(seconds)}</Spacing>
            </H3>
        </Layout>
    );
    const isMountAnimation = seconds === TIME_TO_EXTRACTION_STARTING_TIME_SECONDS;

    return isMountAnimation ? <MountFlickerAnimation>{component}</MountFlickerAnimation> : component;
};
