import React from "react";
import { useService } from "@xstate/react";
import { NotificationActorType } from "../../machines/notificationMachine/types";
import { Li } from "./Components";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";

interface PropsType {
    actor: NotificationActorType;
}

export const Notification = ({ actor }: PropsType) => {
    // @ts-ignore
    const [state] = useService(actor);
    // @ts-ignore
    const { text } = state.context;

    return (
        <MountFlickerAnimation>
            <Li key={actor.id}>{text}</Li>
        </MountFlickerAnimation>
    );
};
