import React, { FC } from "react";
import styled, { css } from "styled-components";
import { gridGutters } from "../../styles/grid";
import { ItemType, ItemKeyType } from "../../types";
import startCase from "lodash/startCase";
import { LabelText } from "../Typography/LabelText";
import { Tooltip } from "../Tooltip";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";
import { playbuttonClickSound } from "../../sounds";
import { ClickMeBlinkingAnimation } from "../Animations/BlinkingAnimation";

const DIMENSION = gridGutters(7);

export const Layout = styled.aside`
    ${({ theme }) => css`
        height: 100%;

        position: fixed;
        top: 0;
        right: 0;

        padding: 1px;
        display: flex;
        flex-direction: column;

        border-left: ${theme.border};
        background: ${theme.color.background[0]};

        > *:not(:last-child) {
            margin-bottom: 1px;
        }
    `}
`;

const Button = styled.button`
    ${({ theme }) => css`
        width: ${DIMENSION};
        height: ${DIMENSION};

        padding: ${gridGutters(1)};
        position: relative;
        display: flex;

        border: ${theme.buttonBorder};

        :disabled {
            opacity: ${theme.opacity.disabled};
        }

        :hover {
            background: ${theme.color.hover};
        }
    `}
`;

const Number = styled.output`
    position: absolute;
    bottom: ${gridGutters(0.5)};
    right: ${gridGutters(0.5)};
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
`;

const TooltipLayout = styled.div`
    position: absolute;
    top: 50%;
    right: calc(100% + ${gridGutters(1)});

    transform: translateY(-50%);
    display: none;

    ${Button}:hover & {
        display: flex;
    }
`;

interface PropsType {
    highlightItemKey?: string;
    items: ItemType[];
    onItemClick: (key: ItemKeyType) => void;
}

export const StockBar: FC<PropsType> = ({ highlightItemKey, items, onItemClick }) => (
    <MountFlickerAnimation>
        <Layout>
            {items.map((item) => {
                const isDisabled = item.numberInStock === 0;

                const ItemButton = (
                    <Button
                        aria-label={startCase(item.name)}
                        disabled={isDisabled}
                        key={item.itemKey}
                        onClick={() => {
                            onItemClick(item.itemKey);
                            playbuttonClickSound();
                        }}
                    >
                        <Img src={item.icon} alt="" />
                        {!isDisabled && (
                            <Number>
                                <LabelText>{item.numberInStock}</LabelText>
                            </Number>
                        )}
                        <TooltipLayout>
                            <Tooltip>{item.name}</Tooltip>
                        </TooltipLayout>
                    </Button>
                );

                return highlightItemKey === item.itemKey ? (
                    <ClickMeBlinkingAnimation key={item.itemKey}>{ItemButton}</ClickMeBlinkingAnimation>
                ) : (
                    ItemButton
                );
            })}
        </Layout>
    </MountFlickerAnimation>
);
