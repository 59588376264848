import { createMachine, assign, sendParent } from "xstate";
import { DialogueBoxEventType, DialogueBoxStateType, DialogueBoxContextType } from "./types";
import { SetDialogueEventType } from "../gameMachine/types";

export const dialogueBoxMachine = createMachine<DialogueBoxContextType, DialogueBoxEventType, DialogueBoxStateType>(
    {
        id: `dialogueBox`,
        context: {
            dialogue: undefined,
        },
        on: {
            NEXT_BUTTON_CLICKED: {
                actions: `forwardToParent`,
            },
            SET_DIALOGUE: [
                {
                    actions: [`updateDialogue`],
                    cond: `isNextButton`,
                    target: `nextButton.show`,
                },
                {
                    actions: [`updateDialogue`],
                    target: `nextButton.hide`,
                },
            ],

            CLEAR_DIALOGUE: {
                actions: `clearDialogue`,
            },
        },
        initial: "nextButton",
        states: {
            nextButton: {
                initial: `show`,
                states: {
                    hide: {},
                    show: {},
                },
            },
        },
    },
    {
        actions: {
            // @ts-ignore
            clearDialogue: assign<DialogueBoxContextType>(() => ({
                dialogue: undefined,
            })),
            forwardToParent: sendParent((_, event) => event),
            // @ts-ignore
            updateDialogue: assign<DialogueBoxContextType, SetDialogueEventType>((_, event) => ({
                dialogue: event.payload.dialogue,
            })),
        },
        guards: {
            // @ts-ignore
            isNextButton: (_, event: SetDialogueEventType) => event.payload.isNextButton,
        },
    }
);
