import React, { FC } from "react";
import styled from "styled-components";
import { ParentProps, SvgProps } from "./types";
import { shapes } from "./shapes";

const Svg = styled.svg<SvgProps>`
    height: ${(p) => p.theme.fontSize[0]};
    flex-shrink: 0;

    fill: ${(p) => p.color || p.theme.color.gray[3]};
`;

export const Icon: FC<ParentProps> = ({ iconKey, color }) => (
    <Svg viewBox="0 0 24 24" color={color}>
        <title>{iconKey}</title>
        {shapes[iconKey]}
    </Svg>
);
