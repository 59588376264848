import React, { FC } from "react";
import styled from "styled-components";
import { gridGutters } from "../../styles/grid";
import { GameEventType } from "../../machines/gameMachine/types";
import { MAP_WIDTH, MAP_HEIGHT } from "../../coords";
import { PathId } from "../../coords/paths";

export const Layout = styled.section`
    padding: ${gridGutters(1)};
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    background: black;
    border: 1px solid white;

    font-size: 12px;
    font-family: monospace;

    > button {
        color: white;
    }
`;

interface PropsType {
    send: (event: GameEventType) => void;
}

export const AdminPanel: FC<PropsType> = ({ send }) => (
    <Layout>
        <output>Most common screen size 1366 × 768</output>
        <output>map width: {MAP_WIDTH}</output>
        <output>map height: {MAP_HEIGHT}</output>

        <button onClick={() => send({ type: "ALIEN_REACHED_STRONGHOLD" })}>
            ALIEN_REACHED_STRONGHOLD
        </button>
        <button onClick={() => send({ type: "TIME_TO_EXTRACTION_COMPLETE" })}>
            TIME_TO_EXTRACTION_COMPLETE
        </button>
        <button
            onClick={() =>
                send({
                    type: "SPAWN_ALIENS",
                    payload: {
                        amount: 1,
                        pathId: PathId.path1,
                    },
                })
            }
        >
            SPAWN_ALIENS
        </button>
        <button onClick={() => send({ type: "NOTIFY_USER", payload: "foo" })}>
            NOTIFY_USER
        </button>
    </Layout>
);
