import { CoordsType, DirectionType } from "../types";

interface PropsType {
    coords: CoordsType;
    direction: DirectionType;
}

export const calcDirectionCoords = ({ coords, direction }: PropsType): CoordsType => {
    const [x, y] = coords;

    if (direction === DirectionType.Up) {
        return [x, y - 1];
    }

    if (direction === DirectionType.UpLeft) {
        return [x - 1, y - 1];
    }

    if (direction === DirectionType.UpRight) {
        return [x + 1, y - 1];
    }

    if (direction === DirectionType.Right) {
        return [x + 1, y];
    }

    if (direction === DirectionType.Down) {
        return [x, y + 1];
    }

    if (direction === DirectionType.DownLeft) {
        return [x - 1, y + 1];
    }

    if (direction === DirectionType.DownRight) {
        return [x + 1, y + 1];
    }

    return [x - 1, y];
};
