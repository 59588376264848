import React from "react";
import { useService } from "@xstate/react";
import { IntroActorType } from "../../machines/introMachine/types";
import { CenterMap, RelativeLayout } from "../Game/components";
import { MapWithActor } from "../Map/MapWithActor";
import { AlienWithActor } from "../Alien/AlienWithActor";
import { BasicGunWithActor } from "../Items/BasicGun/BasicGunWithActor";
import { HudLayout } from "../Hud/HudLayout";
import { TimerLayout } from "../Hud/HudTimerLayout";
import { DialogueBoxWithActor } from "../DialogueBox/DialogueBoxWithActor";
import { StockBar } from "../StockBar";
import { itemsArray } from "../../items";
import { ItemKeyType } from "../../types";
import { ButtonGrid } from "../ButtonGrid";
import { ClickMeBlinkingAnimation } from "../Animations/BlinkingAnimation";
import { LessonScreenWithActor } from "../LessonScreen/LessonScreenWithActor";
import {
    TIME_TO_EXTRACTION_STARTING_TIME_SECONDS,
    INTRO_SONAR_SETTINGS,
    INTRO_SENTRY_SETTINGS,
} from "../../constants";
import { Timer } from "../Timer";
import { difference } from "lodash";
import { Sonar } from "../Items/Sonar";
import { Alien } from "../Alien";
import { paths, PathId } from "../../coords/paths";
import { getAlienPieces, getGunPieces } from "../../util/filterBoardPieces";

interface PropsType {
    actor: IntroActorType;
}

export const Intro = ({ actor }: PropsType) => {
    // @ts-ignore
    const [state, send] = useService(actor);
    const { children, context } = state;
    // @ts-ignore
    const { boardPieces } = context;
    const {
        answerMachine,
        basicGunLessonMachine,
        dialogueBoxMachine,
        mapMachine,
        sonarLessonMachine,
    } = children;

    const aliens = getAlienPieces(boardPieces);
    const guns = getGunPieces(boardPieces);

    // @ts-ignore
    const allStates = Object.keys(actor.machine.config.states);
    const alienStates = [
        `alienMovement`,
        `stopAlien`,
        `clickSentry`,
        `deploySentry`,
        `sentryLesson`,
    ];
    const sonarStates = difference(allStates, [
        `facility`,
        `marines`,
        `aliens`,
        `evac`,
        `controlRoom`,
        `equipment`,
        `sonarUnit`,
        `clickSonar`,
        `deploySonar`,
    ]);
    const stockBarStates = difference(allStates, [
        `facility`,
        `marines`,
        `aliens`,
        `evac`,
        `controlRoom`,
    ]);
    const timerStates = difference(allStates, [
        `facility`,
        `marines`,
        `aliens`,
    ]);

    const getHighlightItemKey = () => {
        if (["clickSonar", "sonarUnit"].some(state.matches)) {
            return ItemKeyType.sonar;
        }

        if (state.matches("clickSentry")) {
            return ItemKeyType.basicGun;
        }

        return undefined;
    };

    if (["sonarLesson"].some(state.matches)) {
        return (
            <LessonScreenWithActor
                answerActor={answerMachine}
                lessonActor={sonarLessonMachine}
            />
        );
    }

    if (["sentryLesson"].some(state.matches)) {
        return (
            <LessonScreenWithActor
                answerActor={answerMachine}
                lessonActor={basicGunLessonMachine}
            />
        );
    }

    return (
        <CenterMap>
            <RelativeLayout>
                <MapWithActor actor={mapMachine} />

                {aliens.map((alien) => (
                    <AlienWithActor key={alien.id} actor={alien} />
                ))}

                {guns.map((gun) => (
                    <BasicGunWithActor key={gun.id} actor={gun} />
                ))}

                {sonarStates.some(state.matches) && (
                    <Sonar coords={INTRO_SONAR_SETTINGS.coords} />
                )}
                {alienStates.some(state.matches) && (
                    <Alien coords={paths[PathId.path1][0]} />
                )}

                {["alienMovement", "stopAlien", `clickSentry`].some(
                    state.matches
                ) && <Alien coords={paths[PathId.path1][1]} />}

                <HudLayout>
                    <TimerLayout>
                        {timerStates.some(state.matches) && (
                            <Timer
                                seconds={
                                    TIME_TO_EXTRACTION_STARTING_TIME_SECONDS
                                }
                            />
                        )}
                    </TimerLayout>
                    <DialogueBoxWithActor actor={dialogueBoxMachine} />
                </HudLayout>

                {stockBarStates.some(state.matches) && (
                    <StockBar
                        highlightItemKey={getHighlightItemKey()}
                        items={itemsArray}
                        onItemClick={(itemKey) =>
                            send({
                                type: "ITEM_BUTTON_CLICKED",
                                payload: itemKey,
                            })
                        }
                    />
                )}

                {["deploySonar"].some(state.matches) && (
                    <ClickMeBlinkingAnimation>
                        <ButtonGrid
                            buttonCoords={[INTRO_SONAR_SETTINGS.coords]}
                            onButtonClick={(coords) =>
                                send({
                                    type: "COORD_BUTTON_CLICKED",
                                    payload: coords,
                                })
                            }
                        />
                    </ClickMeBlinkingAnimation>
                )}

                {["deploySentry"].some(state.matches) && (
                    <ClickMeBlinkingAnimation>
                        <ButtonGrid
                            buttonCoords={[INTRO_SENTRY_SETTINGS.coords]}
                            onButtonClick={(coords) =>
                                send({
                                    type: "COORD_BUTTON_CLICKED",
                                    payload: coords,
                                })
                            }
                        />
                    </ClickMeBlinkingAnimation>
                )}
            </RelativeLayout>
        </CenterMap>
    );
};
