import React, { useEffect } from "react";
import { theme } from "./styles/theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/GlobalStyles";
import { Game } from "./components/Game";

// https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
function handleFirstTab(e: any) {
    if (e.keyCode === 9) {
        // the "I am a keyboard user" key
        document.body.classList.add("user-is-tabbing");
        window.removeEventListener("keydown", handleFirstTab);
    }
}

export const App = () => {
    useEffect(() => {
        window.addEventListener("keydown", handleFirstTab);

        return () => {
            window.removeEventListener("keydown", handleFirstTab);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyles />
                <Game />
            </>
        </ThemeProvider>
    );
};
