import React, { FC } from "react";
import styled from "styled-components";
import { h3Styles } from "./H3";

const StyledH4 = styled.h4`
    ${h3Styles};
    color: ${(p) => p.theme.color.text[5]};
`;

export const H4: FC = (props) => <StyledH4 {...props} />;
