import React, { FC, useState, useEffect } from "react";
import { AlienActorType } from "../../machines/alienMachine/types";
import { useService } from "@xstate/react";
import { CoordsType } from "../../types";
import { random } from "lodash";
import { Alien } from ".";

const RANGE = 0.5;

const scatterCoords = (coords: CoordsType): CoordsType => [
    random(coords[0] - RANGE, coords[0] + RANGE, true),
    random(coords[1] - RANGE, coords[1] + RANGE, true),
];

interface PropsType {
    actor: AlienActorType;
}

export const AlienWithActor: FC<PropsType> = ({ actor }) => {
    // @ts-ignore
    const [state] = useService(actor);
    const { coords } = state.context;
    const [scatteredCoords, setScatteredCoords] = useState(scatterCoords(coords));

    useEffect(() => {
        setScatteredCoords(scatterCoords(coords));
    }, [coords]);

    return state.matches("moving.hidden") ? null : <Alien coords={scatteredCoords} />;
};
