import { StateMachine } from "xstate";
import { ItemKeyType, ItemSettingsType } from "../../types";
import { sonarMachine } from "./sonarMachine";
import { basicGunMachine } from "./basicGunMachine";

type ItemKeyMachineMapType = {
    [propName in ItemKeyType]: (itemSettings: ItemSettingsType) => StateMachine<any, any, any>;
};

export const itemKeyMachineMap: ItemKeyMachineMapType = {
    [ItemKeyType.sonar]: sonarMachine,
    [ItemKeyType.basicGun]: basicGunMachine,
};
