import React from "react";
import { MenuScreenLayout } from "./MenuScreenLayout";
import { H1 } from "../Typography/H1";
import { Paragraph } from "../Typography/Paragraph";
import { externalLinkProps } from "../../util/externalLinkProps";
import { GridGutterRows, GridSquareRows } from "../Grid";
import { FeebackForm } from "../FeedbackForm";

export const GameCompleteScreen = () => (
    <MenuScreenLayout>
        <H1>Game Complete</H1>
        <GridGutterRows amount={3} />

        <Paragraph>
            Your team has been safely extracted from the facility. <br />
            Follow{" "}
            <a
                href="https://twitter.com/bradwoodsio?ref_src=twsrc%5Etfw"
                {...externalLinkProps}
            >
                Brad Woods
            </a>{" "}
            on Twitter to be notified of new content. If you have any feedback,
            please enter it in the input below:
        </Paragraph>
        <GridSquareRows amount={1} />
        <FeebackForm />
    </MenuScreenLayout>
);
