const GRID_SQUARE = 40;
const GRID_GUTTER = 8;

export const gridSquares = (numOfGridSquares: number) => {
    const squares = numOfGridSquares * GRID_SQUARE;
    const numOfGutters = numOfGridSquares - 1;
    const gutters = numOfGutters * GRID_GUTTER;

    return `${squares + gutters}px`;
};

export const gridGutters = (numOfGutters: number) => `${numOfGutters * GRID_GUTTER}px`;
