import { StateMachine } from "xstate";

export type XCoordType = number;
export type YCoordType = number;
export type CoordsType = [XCoordType, YCoordType];

export enum DirectionType {
    Down = "Down",
    DownLeft = "DownLeft",
    DownRight = "DownRight",
    Left = "Left",
    Right = "Right",
    Up = "Up",
    UpLeft = "UpLeft",
    UpRight = "UpRight",
}

// There is no clean way of extending an enum. For now, duplicating keys as work around
// https://github.com/microsoft/TypeScript/issues/17592
export enum ItemKeyType {
    sonar = "sonar",
    basicGun = "basicGun",
}

export enum BoardPieceKeyType {
    alien = "alien",
    sonar = "sonar",
    basicGun = "basicGun",
}

// -----------------------------------------------------
export interface ItemType {
    hasDirection: boolean;
    icon: string;
    isUnlocked: boolean;
    itemKey: ItemKeyType;
    lessonMachine: StateMachine<any, any, any>;
    name: string;
    numberInStock: number;
}

export interface LessonSystemsType {
    [name: string]: boolean;
}

export interface ItemSettingsType {
    coords: CoordsType;
    direction?: DirectionType;
}
