import React, { FC } from "react";
import styled from "styled-components";

const Span = styled.span`
    font-size: ${(p) => p.theme.fontSize[0]};
    /* color: ${(p) => p.theme.color.text[10]}; */
        color: ${(p) => p.theme.color.clickable[0]};
    letter-spacing: 0.1em;

    /* 2. move text to sit on baseline */
    transform: translateY(0.4em);
    /* 3. space between multi-lines */
    line-height: 1.55;
    display: block;

    &::before {
        content: "";
        display: block;
        height: 0;
        /* 1. adjust margin-top to adjust the height of the outer element (it should be high enough to cover the top of an ascender and none of the decender below the baseline */
        margin-top: -12px;
    }
`;

interface PropsType {
    children: string;
}

export const ButtonText: FC<PropsType> = (props) => <Span {...props} />;
