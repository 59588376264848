import { createMachine } from "xstate";
import { GameEventType, GameStateType, GameContextType } from "./types";
import { initialContext } from "./initialContext";
import { options } from "./options";

export const gameMachine = createMachine<
    GameContextType,
    GameEventType,
    GameStateType
>(
    {
        id: `game`,
        context: initialContext,
        initial: `gameStart`,
        states: {
            gameStart: {
                on: {
                    START_BUTTON_CLICKED: `gameIntro`,
                },
            },
            gameIntro: {
                invoke: [
                    {
                        autoForward: true,
                        id: `introMachine`,
                        src: `introMachine`,
                    },
                ],
                on: {
                    INTRO_COMPLETE: {
                        target: "gamePlaying",
                    },
                },
            },
            gamePlaying: {
                entry: [`showFogOfWar`, `showMap`, `spawnStartingItems`],
                exit: `stopBoardPieces`,
                initial: `map`,
                invoke: [
                    {
                        id: `dialogueBoxMachine`,
                        src: `dialogueBoxMachine`,
                    },
                    {
                        autoForward: true,
                        id: `extractionMachine`,
                        src: `extractionMachine`,
                    },
                    {
                        autoForward: true,
                        id: `queenMachine`,
                        src: `queenMachine`,
                    },
                    {
                        autoForward: true,
                        id: `mapMachine`,
                        src: `mapMachine`,
                    },
                    {
                        autoForward: true,
                        id: `notificationsMachine`,
                        src: `notificationsMachine`,
                    },
                    {
                        autoForward: true,
                        id: `radarPingMachine`,
                        src: `radarPingMachine`,
                    },
                    {
                        id: `renderLoop`,
                        src: `renderLoop`,
                    },
                    {
                        autoForward: true,
                        id: `stockMachine`,
                        src: `stockMachine`,
                    },
                ],
                on: {
                    // "*": {
                    //     actions: [`log`],
                    // },
                    ALIEN_REACHED_STRONGHOLD: `gameOver`,
                    CLEAR_DIALOGUE: {
                        actions: `forwardToDialogueBox`,
                    },
                    CLEAR_FOG: {
                        actions: `updateVisibleCoordsList`,
                    },
                    DESTROY_GUN: [
                        {
                            actions: [
                                `notifyUserGunDestroyed`,
                                `removeBoardPiece`,
                            ],
                            cond: `isBoardPieceAvailable`,
                        },
                    ],
                    FRAME: {
                        actions: [`broadcastToBoardPieces`, `forwardToMap`],
                    },
                    FRAME_TIMER_TIC: {
                        actions: `frame`,
                    },
                    KILL_ALIEN: {
                        actions: `removeBoardPiece`,
                    },
                    SET_DIALOGUE: {
                        actions: `forwardToDialogueBox`,
                    },
                    SPAWN_ALIENS: {
                        actions: `spawnAliens`,
                    },
                    TIME_TO_EXTRACTION_COMPLETE: `gameComplete`,
                },
                states: {
                    map: {
                        on: {
                            DEPLOY_COMPLETE: {
                                target: "programmingItem",
                                actions: `spawnLesson`,
                            },
                        },
                    },
                    programmingItem: {
                        exit: `removeLesson`,
                        invoke: {
                            id: `answerMachine`,
                            src: `answerMachine`,
                        },
                        on: {
                            ANSWER_ENTERED: {
                                actions: `forwardToSelectedLesson`,
                            },
                            LESSON_COMPLETE: {
                                actions: [`spawnItem`, `clearFogAroundItem`],
                            },
                            LESSON_EXIT_BUTTON_CLICKED: `map`,
                        },
                    },
                },
            },
            gameOver: {
                on: {
                    RESTART_BUTTON_CLICKED: {
                        target: `gamePlaying`,
                        actions: `resetContext`,
                    },
                },
            },
            gameComplete: {
                type: `final`,
            },
        },
    },
    // @ts-ignore
    options
);
