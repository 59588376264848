import React from "react";
import { useService } from "@xstate/react";
import { Map } from ".";
import { MapActorType } from "../../machines/mapMachine/types";
import { FogOfWar } from "../FogOfWar";
import { MapHighLighter } from "../MapHighlighter";

interface PropsType {
    actor: MapActorType;
}

export const MapWithActor = ({ actor }: PropsType) => {
    // @ts-ignore
    const [state] = useService(actor);
    // @ts-ignore
    const { highlightCoordsList, visibleMapCoordsList } = state.context;

    return (
        <>
            {state.matches("image.show") && <Map />}
            {state.matches("fogOfWar.show") && (
                <FogOfWar visibleMapCoordsList={visibleMapCoordsList} />
            )}
            {highlightCoordsList && (
                <MapHighLighter coordsList={highlightCoordsList} />
            )}
        </>
    );
};
