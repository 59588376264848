import styled from "styled-components";
import { gridSquares } from "../../styles/grid";

export const MenuScreenLayout = styled.section`
    width: 100%;
    min-height: 100vh;

    padding: ${gridSquares(1)};
    padding-top: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
