import { assign, createMachine, sendParent } from "xstate";
import {
    SonarLessonEventType,
    SonarLessonStateType,
    SonarLessonContextType,
} from "./types";
import {
    doesAnswerHaveInitialState,
    doesAnswerHaveStatePath,
} from "../../../machineQueries/answerQueries";
import { AnswerEnteredEventType } from "../../gameMachine/types";
import { LessonIdType } from "../types";

export const sonarLessonMachine = createMachine<
    SonarLessonContextType,
    SonarLessonEventType,
    SonarLessonStateType
>(
    {
        context: {
            itemKey: undefined,
            itemSettings: undefined,
            systems: {
                initialState: false,
                activeState: false,
            },
            template: `
const config = {
    initial: "myState1",
    states: {
        myState1: {},
    }
}
            `,
        },
        id: LessonIdType.sonarLesson,
        initial: `incorrect`,
        meta: {
            correctAnswer: `
                const config = {
                    initial: "active",
                    states: {
                        active: {},
                    },
                };
            `,
            instructions: `
To become operational, the config requires:

- Initial state:
    - **active**
- States:
    - **active**
            `,
        },
        on: {
            ANSWER_ENTERED: {
                actions: `updateSystemsStatus`,
                target: `determining`,
            },
            LESSON_EXIT_BUTTON_CLICKED: {
                actions: "broadcast",
            },
        },
        states: {
            correct: {},
            determining: {
                always: [
                    {
                        actions: `broadcastLessonComplete`,
                        cond: `allSystemsOperational`,
                        target: `correct`,
                    },
                    {
                        target: `incorrect`,
                    },
                ],
            },
            incorrect: {},
        },
    },
    {
        actions: {
            broadcastLessonComplete: sendParent((context) => ({
                type: "LESSON_COMPLETE",
                payload: {
                    itemKey: context.itemKey,
                    itemSettings: context.itemSettings,
                },
            })),
            broadcast: sendParent((_, event) => event),
            // @ts-ignore
            updateSystemsStatus: assign<
                SonarLessonContextType,
                AnswerEnteredEventType
            >((_, event) => {
                const answer = event.payload;

                return {
                    systems: {
                        initialState: doesAnswerHaveInitialState({
                            answer,
                            initialStateKey: "active",
                        }),
                        activeState: doesAnswerHaveStatePath({
                            answer,
                            statePath: "active",
                        }),
                    },
                };
            }),
        },
        guards: {
            allSystemsOperational: (context: SonarLessonContextType) =>
                Object.values(context.systems).every(
                    (status) => status === true
                ),
        },
    }
);
