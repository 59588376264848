import { flatten } from "lodash";
import { CoordsType, XCoordType, YCoordType } from "../types";

export const MAP_SQUARE_DIMENSION = 40;

export const MAP_ROW_COUNT = 29;
export const MAP_COL_COUNT = 33;

export const MAP_WIDTH = MAP_COL_COUNT * MAP_SQUARE_DIMENSION;
export const MAP_HEIGHT = MAP_ROW_COUNT * MAP_SQUARE_DIMENSION;

export const mapCoordsList = flatten(
    Array(MAP_ROW_COUNT)
        .fill(undefined)
        .map(
            (_, rowIndex) =>
                Array(MAP_COL_COUNT)
                    .fill(undefined)
                    .map((_, colIndex) => [colIndex, rowIndex]) as CoordsType[]
        )
);

export const coordToCenterPosition = (coord: XCoordType | YCoordType) =>
    coord * MAP_SQUARE_DIMENSION + MAP_SQUARE_DIMENSION / 2;
export const coordToLeftTopPosition = (coord: XCoordType | YCoordType) => coord * MAP_SQUARE_DIMENSION;

export const coordsToCenterPosition = (coords: CoordsType) => coords.map((coord) => coordToCenterPosition(coord));
export const coordsToLeftTopPosition = (coords: CoordsType) => coords.map((coord) => coordToLeftTopPosition(coord));
