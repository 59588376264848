import { createMachine, assign, sendParent, sendUpdate, send } from "xstate";
import { AlienEventType, AlienStateType, AlienContextType } from "./types";
import { PathId, paths } from "../../coords/paths";
import { isEqual, intersectionWith } from "lodash";
import { FrameEventType } from "../gameMachine/types";
import { BoardPieceKeyType } from "../../types";
import {
    getGunPieces,
    getDestructableItemPieces,
} from "../../util/filterBoardPieces";
import { isMoveFrame } from "../../util/isMoveFrame";

interface PropsType {
    pathId: PathId;
}

export const alienMachine = ({ pathId }: PropsType) =>
    createMachine<AlienContextType, AlienEventType, AlienStateType>(
        {
            context: {
                coords: paths[pathId][0],
                killZoneCoordsList: [paths[pathId][0]],
            },
            id: BoardPieceKeyType.alien,
            initial: `moving`,
            meta: {
                pathId,
            },
            states: {
                dead: {
                    type: "final",
                },
                moving: {
                    on: {
                        FRAME: [
                            {
                                actions: `broadcastReachedStronghold`,
                                cond: `isAtEndOfPath`,
                                target: "reachedPlayer",
                            },
                            {
                                actions: `destroyItem`,
                                cond: `isNearGun`,
                            },
                            {
                                actions: [
                                    `checkVisibility`,
                                    `updateCoords`,
                                    sendUpdate(),
                                ],
                                cond: `isMoveFrame`,
                            },
                        ],
                        VISIBILITY_CHECK: [
                            {
                                cond: `isVisible`,
                                target: "moving.visible",
                            },
                            {
                                target: "moving.hidden",
                            },
                        ],
                    },
                    initial: "hidden",
                    states: {
                        hidden: {},
                        visible: {},
                    },
                },
                reachedPlayer: {
                    type: "final",
                },
            },
        },
        {
            actions: {
                broadcastReachedStronghold: sendParent(
                    "ALIEN_REACHED_STRONGHOLD"
                ),
                // using send here as I need to hold onto the FRAME event to access it's payload (the sonar items).
                // @ts-ignore
                checkVisibility: send((context, event: FrameEventType) => ({
                    type: "VISIBILITY_CHECK",
                    payload: event.payload,
                })),
                // @ts-ignore
                destroyItem: sendParent(
                    (context: AlienContextType, event: FrameEventType) => {
                        const { killZoneCoordsList } = context;
                        const { boardPieces } = event.payload;
                        const items = getDestructableItemPieces(boardPieces);
                        const itemsInKillZone = intersectionWith(
                            items,
                            killZoneCoordsList,
                            (gun, killZoneCoords) =>
                                isEqual(
                                    gun.state.context.coords,
                                    killZoneCoords
                                )
                        );
                        const id = itemsInKillZone[0]?.id;
                        return {
                            type: "DESTROY_GUN",
                            payload: id,
                        };
                    }
                ),
                // @ts-ignore
                updateCoords: assign<AlienContextType>((context, event) => {
                    const { coords } = context;
                    const path = paths[pathId];
                    const index = path.findIndex(
                        (pathCoords) =>
                            pathCoords[0] === coords[0] &&
                            pathCoords[1] === coords[1]
                    );
                    const newCoords = path[index + 1];
                    return {
                        coords: newCoords,
                        killZoneCoordsList: [newCoords],
                    };
                }),
            },
            guards: {
                isAtEndOfPath: (context: AlienContextType) => {
                    const { coords } = context;
                    const path = paths[pathId];
                    const index = path.findIndex(
                        (pathCoords) =>
                            pathCoords[0] === coords[0] &&
                            pathCoords[1] === coords[1]
                    );
                    return index === path.length - 1;
                },
                // @ts-ignore
                isMoveFrame: (_, event: FrameEventType) => {
                    const { frameCount } = event.payload;
                    return isMoveFrame(frameCount);
                },
                // @ts-ignore
                isNearGun: (
                    context: AlienContextType,
                    event: FrameEventType
                ) => {
                    const { killZoneCoordsList } = context;
                    const { boardPieces } = event.payload;
                    const guns = getGunPieces(boardPieces);
                    return (
                        intersectionWith(
                            guns,
                            killZoneCoordsList,
                            (gun, killZoneCoords) =>
                                isEqual(
                                    gun.state.context.coords,
                                    killZoneCoords
                                )
                        ).length > 0
                    );
                },
                // @ts-ignore
                isVisible: (
                    context: AlienContextType,
                    event: FrameEventType
                ) => {
                    const { coords: alienCoords } = context;
                    const { visibleMapCoordsList } = event.payload;

                    return visibleMapCoordsList.some((coords) =>
                        isEqual(coords, alienCoords)
                    );
                },
            },
        }
    );
