import React, { FC } from "react";
import { AnswerActorType } from "../../machines/answerMachine/types";
import { LessonScreen } from ".";
import { useService } from "@xstate/react";
import { LessonActorType } from "../../machines/lessonMachines/types";
// import { STARTING_ANSWER } from "../../machines/answerMachine";
import { itemsObject } from "../../items";
import { ItemKeyType } from "../../types";
import { isDevEnvironment } from "../../util/isDevEnvironment";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";

interface PropsType {
    answerActor: AnswerActorType;
    lessonActor: LessonActorType;
}

export const LessonScreenWithActor: FC<PropsType> = ({
    answerActor,
    lessonActor,
}) => {
    // @ts-ignore
    const [answerState, answerSend] = useService(answerActor);
    // @ts-ignore
    const [lessonState, lessonSend] = useService(lessonActor);
    // @ts-ignore
    const { instructions, correctAnswer } = lessonActor.machine.meta;
    const { answer, cursorStartingPosition } = answerState.context;
    const { itemKey, systems, template } = lessonState.context;
    const item = itemsObject[itemKey as ItemKeyType];
    const { icon, name } = item;

    return (
        <MountFlickerAnimation
            style={{ width: `100%`, minHeight: `100vh`, display: `flex` }}
        >
            <LessonScreen
                answer={isDevEnvironment ? correctAnswer.trim() : answer}
                cursorStartingPosition={cursorStartingPosition}
                example={template.trim()}
                heading={`${name} System`}
                icon={icon}
                instructionsMarkdown={instructions.trim()}
                isComplete={lessonState.matches("correct")}
                onChange={(value) =>
                    answerSend({
                        type: "ANSWER_ENTERED",
                        payload: value,
                    })
                }
                onExitClick={() => lessonSend("LESSON_EXIT_BUTTON_CLICKED")}
                systems={systems}
            />
        </MountFlickerAnimation>
    );
};
