import { assign, createMachine, sendParent } from "xstate";
import {
    BasicGunLessonEventType,
    BasicGunLessonStateType,
    BasicGunLessonContextType,
} from "./types";
import {
    doesAnswerHaveInitialState,
    doesAnswerHaveStatePath,
    doesAnswerHaveTransition,
} from "../../../machineQueries/answerQueries";
import { AnswerEnteredEventType } from "../../gameMachine/types";
import { TransitionType } from "../../../machineQueries/machineQueries";
import { LessonIdType } from "../types";

const startFiringTransition: TransitionType = {
    eventType: "START_FIRING",
    sourceStatePath: "notFiring",
    targetStateKey: "firing",
};

const stopFiringTransition: TransitionType = {
    eventType: "STOP_FIRING",
    sourceStatePath: "firing",
    targetStateKey: "notFiring",
};

export const basicGunLessonMachine = createMachine<
    BasicGunLessonContextType,
    BasicGunLessonEventType,
    BasicGunLessonStateType
>(
    {
        context: {
            itemKey: undefined,
            itemSettings: undefined,
            systems: {
                initialState: false,
                notFiringState: false,
                firingState: false,
                startFiringTransition: false,
                stopFiringTransition: false,
            },
            template: `
const config = {
    initial: "myState1",
    states: {
        myState1: {
            on: {
                MY_EVENT_1: "myState2"
            }
        },
        myState2: {
            on: {
                MY_EVENT_2: "myState1"
            }
        },
    }
}
            `,
        },
        id: LessonIdType.basicGunLesson,
        initial: `incorrect`,
        meta: {
            correctAnswer: `
const config = {
    initial: "notFiring",
    states: {
        notFiring: {
            on: {
                START_FIRING: "firing"
            }
        },
        firing: {
            on: {
                STOP_FIRING: "notFiring"
            }
        },
    }
}
            `,
            instructions: `
To become operational, the config requires:

- Initial state:
    - **notFiring**
- States:
    - **notFiring**
    - **firing**
- Transitions:
    - **START_FIRING** event that changes state from **notFiring** to **firing**
    - **STOP_FIRING** event that changes state from **firing** to **notFiring**
            `,
        },
        on: {
            ANSWER_ENTERED: {
                actions: `updateSystemsStatus`,
                target: `determining`,
            },
            LESSON_EXIT_BUTTON_CLICKED: {
                actions: "broadcast",
            },
        },
        states: {
            correct: {},
            determining: {
                always: [
                    {
                        actions: `broadcastLessonComplete`,
                        cond: `allSystemsOperational`,
                        target: `correct`,
                    },
                    {
                        target: `incorrect`,
                    },
                ],
            },
            incorrect: {},
        },
    },
    {
        actions: {
            broadcastLessonComplete: sendParent((context) => ({
                type: "LESSON_COMPLETE",
                payload: {
                    itemKey: context.itemKey,
                    itemSettings: context.itemSettings,
                },
            })),
            broadcast: sendParent((_, event) => event),
            // @ts-ignore
            updateSystemsStatus: assign<
                BasicGunLessonContextType,
                AnswerEnteredEventType
            >((_, event) => {
                const answer = event.payload;

                return {
                    systems: {
                        initialState: doesAnswerHaveInitialState({
                            answer,
                            initialStateKey: "notFiring",
                        }),
                        notFiringState: doesAnswerHaveStatePath({
                            answer,
                            statePath: "notFiring",
                        }),
                        firingState: doesAnswerHaveStatePath({
                            answer,
                            statePath: "firing",
                        }),
                        startFiringTransition: doesAnswerHaveTransition({
                            answer,
                            transition: startFiringTransition,
                        }),
                        stopFiringTransition: doesAnswerHaveTransition({
                            answer,
                            transition: stopFiringTransition,
                        }),
                    },
                };
            }),
        },
        guards: {
            allSystemsOperational: (context: BasicGunLessonContextType) =>
                Object.values(context.systems).every(
                    (status) => status === true
                ),
        },
    }
);
