import React, { FC } from "react";
import styled from "styled-components";

const StyledH1 = styled.h1`
    font-size: ${(p) => p.theme.fontSize[3]};
    font-weight: ${(p) => p.theme.fontWeight[1]};
    color: ${(p) => p.theme.color.text[10]};

    /* 2. move text to sit on baseline */
    transform: translateY(0.4em);
    /* 3. space between multi-lines */
    line-height: 1.55;
    display: block;

    &::before {
        content: "";
        display: block;
        height: 0;
        /* 1. adjust margin-top to adjust the height of the outer element (it should be high enough to cover the top of an ascender and none of the decender below the baseline */
        margin-top: -20px;
    }
`;

export const H1: FC = (props) => <StyledH1 {...props} />;
