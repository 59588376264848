import React, { FC, useEffect } from "react";
import { CoordsType } from "../../types";
import { LookAtBlinkingAnimation } from "../Animations/BlinkingAnimation";
import { coordsToCenterPosition, MAP_SQUARE_DIMENSION } from "../../coords";
import { zip } from "lodash";
import styled from "styled-components";
import { gridGutters } from "../../styles/grid";
import { playMapHighlightSound } from "../../sounds";

const Square = styled.div`
    outline: ${gridGutters(1)} solid ${(p) => p.theme.color.border};
    outline-offset: ${gridGutters(1)};

    position: absolute;
`;

interface PropsType {
    coordsList: CoordsType[];
}

export const MapHighLighter: FC<PropsType> = ({ coordsList }) => {
    useEffect(() => {
        playMapHighlightSound();

        const intervalId = setInterval(playMapHighlightSound, 1700);

        return () => clearInterval(intervalId);
    }, []);

    const centerPositions = coordsList.map((coords) =>
        coordsToCenterPosition(coords)
    );
    const [allX, allY] = zip(...centerPositions) as [number[], number[]];

    const minX = Math.min(...allX);
    const minY = Math.min(...allY);
    const maxX = Math.max(...allX);
    const maxY = Math.max(...allY);

    const width = `${maxX - minX + MAP_SQUARE_DIMENSION}px`;
    const height = `${maxY - minY + MAP_SQUARE_DIMENSION}px`;
    const left = `${minX - MAP_SQUARE_DIMENSION / 2}px`;
    const top = `${minY - MAP_SQUARE_DIMENSION / 2}px`;

    const style = {
        width,
        height,
        left,
        top,
    };

    return (
        <LookAtBlinkingAnimation>
            <Square style={style} />
        </LookAtBlinkingAnimation>
    );
};
