import styled from "styled-components";
import { MAP_WIDTH } from "../../coords";
import { gridGutters } from "../../styles/grid";

export const HudLayout = styled.header`
    width: 100%;
    max-width: ${MAP_WIDTH}px;

    position: fixed;
    top: ${gridGutters(1)};
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
`;
