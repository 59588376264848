import React from "react";
import { LabelText } from "../Typography/LabelText";
import styled from "styled-components";
import { gridGutters } from "../../styles/grid";

const Layout = styled.div`
    position: relative;
    padding: ${gridGutters(0.4)} ${gridGutters(0.8)};
    background: ${(p) => p.theme.color.gray[5]};
`;

const P = styled.p`
    position: relative;
    span {
        font-weight: ${(p) => p.theme.fontWeight[1]};
        color: ${(p) => p.theme.color.text[0]};
    }
`;

export const BetaBadge = () => (
    <Layout>
        <P>
            <LabelText>beta</LabelText>
        </P>
    </Layout>
);
