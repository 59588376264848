import styled from "styled-components";
import { AnswerInputPropsType } from ".";
import { gridGutters } from "../../styles/grid";

export const Layout = styled.div<{ disabled: AnswerInputPropsType["disabled"] }>`
    width: 100%;
    height: 100%;
    overflow: hidden;

    padding: ${gridGutters(0.5)};
    /* line numbers */
    padding-left: 0;

    .react-codemirror2 {
        height: 100%;
        overflow: auto;

        .CodeMirror {
            height: 100%;
            background: transparent;
        }

        /* https://codemirror.net/doc/manual.html#styling  */
        .CodeMirror-cursor {
            width: 12px;
            background: gray;
            border: none;
        }
    }
`;
