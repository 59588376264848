import styled, { css } from "styled-components";

export const GridButton = styled.button`
    ${({ theme }) => css`
        width: 100%;
        height: 100%;

        border: ${theme.buttonBorder};

        :hover {
            background: ${theme.color.hover};
        }
    `}
`;
