import { CoordsType, DirectionType } from "../../../types";

interface PropsType {
    coords: CoordsType;
    direction: DirectionType;
}

// add range to this
export const calcKillZoneCoordsList = ({ coords, direction }: PropsType): CoordsType[] => {
    const [x, y] = coords;
    const RANGE_GRID_SQUARES = 1;

    switch (direction) {
        case DirectionType.Up:
            return [[x, y - RANGE_GRID_SQUARES]];

        case DirectionType.UpRight:
            return [[x + RANGE_GRID_SQUARES, y - RANGE_GRID_SQUARES]];

        case DirectionType.Right:
            return [[x + RANGE_GRID_SQUARES, y]];

        case DirectionType.DownRight:
            return [[x + RANGE_GRID_SQUARES, y + RANGE_GRID_SQUARES]];

        case DirectionType.Down:
            return [[x, y + RANGE_GRID_SQUARES]];

        case DirectionType.DownLeft:
            return [[x - RANGE_GRID_SQUARES, y + RANGE_GRID_SQUARES]];

        case DirectionType.Left:
            return [[x - RANGE_GRID_SQUARES, y]];

        case DirectionType.UpLeft:
            return [[x - RANGE_GRID_SQUARES, y - RANGE_GRID_SQUARES]];

        default:
            throw Error(`Unknown direction: ${direction}`);
    }
};
