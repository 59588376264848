import React, { FC } from "react";
import { MenuScreenLayout } from "./MenuScreenLayout";
import { StyledButton } from "../Button";
import { H1 } from "../Typography/H1";
import { Paragraph } from "../Typography/Paragraph";
// import { BradWoodsIoLink } from "../BradWoodsIoLink";
import { GridGutterRows, GridSquareRows } from "../Grid";
import styled from "styled-components";
import { ClickMeBlinkingAnimation } from "../Animations/BlinkingAnimation";
import { ButtonText } from "../Typography/ButtonText";
import { playbuttonClickSound } from "../../sounds";
import { BetaBadge } from "../BetaBadge";

const Row = styled.div`
    display: flex;
    align-items: flex-end;
`;

const BetaBadgeLayout = styled.div`
    display: flex;
    transform: translateY(2px);
`;

interface PropsType {
    onStartClick: () => void;
}

export const GameStartScreen: FC<PropsType> = ({ onStartClick }) => (
    <MenuScreenLayout>
        <Row>
            <H1>XState Evac</H1>
            &nbsp;&nbsp;
            <BetaBadgeLayout>
                <BetaBadge />
            </BetaBadgeLayout>
        </Row>

        <GridGutterRows amount={3} />
        {/* same text as page description */}
        <Paragraph>Master XState through play.</Paragraph>
        <GridSquareRows amount={1} />
        <Paragraph>
            This was a M.V.P project experimenting with learning through play.
            <br />
            No longer in development.
        </Paragraph>
        <GridSquareRows amount={1} />
        <StyledButton
            onClick={() => {
                onStartClick();
                playbuttonClickSound();
            }}
        >
            <ClickMeBlinkingAnimation>
                <ButtonText>Start Game</ButtonText>
            </ClickMeBlinkingAnimation>
        </StyledButton>
        {/* <BradWoodsIoLink /> */}
    </MenuScreenLayout>
);
