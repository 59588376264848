import { CoordsType } from "../types";
import { path1CoordsList } from "./path1CoordsList";
import { path2CoordsList } from "./path2CoordsList";
import { path3CoordsList } from "./path3CoordsList";

export enum PathId {
    path1 = "path1",
    path2 = "path2",
    path3 = "path3",
}

export type PathsType = Record<PathId, CoordsType[]>;

export const paths: PathsType = {
    [PathId.path1]: path1CoordsList,
    [PathId.path2]: path2CoordsList,
    [PathId.path3]: path3CoordsList,
};
