import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { AlienWithActor } from "../Alien/AlienWithActor";
import { GameStartScreen } from "../MenuScreens/GameStartScreen";
import { AdminPanel } from "../StoryHelpers/AdminPanel";
import { GameOverScreen } from "../MenuScreens/GameOverScreen";
import { GameCompleteScreen } from "../MenuScreens/GameCompleteScreen";
import { gameMachine } from "../../machines/gameMachine";
import { GameEventType } from "../../machines/gameMachine/types";
import { TimerWithActor } from "../Timer/TimerWithActor";
import { HudLayout } from "../Hud/HudLayout";
import { TimerLayout } from "../Hud/HudTimerLayout";
import { BasicGunWithActor } from "../Items/BasicGun/BasicGunWithActor";
import { StockBarWithActor } from "../StockBar/StockBarWithActor";
import { LessonScreenWithActor } from "../LessonScreen/LessonScreenWithActor";
import {
    getAlienPieces,
    getGunPieces,
    getSonarPieces,
} from "../../util/filterBoardPieces";
import { SonarWithActor } from "../Items/Sonar/SonarWithActor";
import { DialogueBoxWithActor } from "../DialogueBox/DialogueBoxWithActor";
import { isDevEnvironment } from "../../util/isDevEnvironment";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";
import { CenterMap, RelativeLayout } from "./components";
import { MapWithActor } from "../Map/MapWithActor";
import { Intro } from "../Intro";
import { ScanLines } from "../ScanLiines";
import { Notifications } from "../Notifications";

interface PropsType {
    fastForwardEvents?: GameEventType[];
}

export const Game = ({ fastForwardEvents }: PropsType) => {
    const [state, send] = useMachine(gameMachine);
    const { children, context } = state;
    // @ts-ignore
    const { boardPieces, selectedLesson } = context;
    const {
        answerMachine,
        dialogueBoxMachine,
        extractionMachine,
        introMachine,
        mapMachine,
        notificationsMachine,
        stockMachine,
    } = children;

    useEffect(() => {
        if (fastForwardEvents) {
            fastForwardEvents.forEach((event) => {
                send(event);
            });
        }
    }, [fastForwardEvents, send]);

    const aliens = getAlienPieces(boardPieces);
    const sonars = getSonarPieces(boardPieces);
    const guns = getGunPieces(boardPieces);

    const onStartClick = () => send("START_BUTTON_CLICKED");
    const onRestartClick = () => send("RESTART_BUTTON_CLICKED");

    const component = () => {
        if (state.matches("gameStart")) {
            return (
                <MountFlickerAnimation>
                    <GameStartScreen onStartClick={onStartClick} />
                </MountFlickerAnimation>
            );
        }

        if (state.matches("gameIntro")) {
            return <Intro actor={introMachine} />;
        }

        if (state.matches("gamePlaying.map")) {
            return (
                <CenterMap>
                    <RelativeLayout>
                        {mapMachine && <MapWithActor actor={mapMachine} />}

                        {aliens.map((alien) => (
                            <AlienWithActor key={alien.id} actor={alien} />
                        ))}

                        {sonars.map((sonar) => (
                            <SonarWithActor key={sonar.id} actor={sonar} />
                        ))}

                        {guns.map((gun) => (
                            <BasicGunWithActor key={gun.id} actor={gun} />
                        ))}

                        <HudLayout>
                            {extractionMachine && (
                                <TimerLayout>
                                    <TimerWithActor actor={extractionMachine} />
                                </TimerLayout>
                            )}
                            {dialogueBoxMachine && (
                                <DialogueBoxWithActor
                                    actor={dialogueBoxMachine}
                                />
                            )}
                        </HudLayout>

                        {stockMachine && (
                            <StockBarWithActor actor={stockMachine} />
                        )}

                        {notificationsMachine && (
                            <Notifications actor={notificationsMachine} />
                        )}

                        {isDevEnvironment && <AdminPanel send={send} />}
                    </RelativeLayout>
                </CenterMap>
            );
        }

        if (state.matches("gamePlaying.programmingItem")) {
            if (answerMachine && selectedLesson) {
                return (
                    <LessonScreenWithActor
                        answerActor={answerMachine}
                        lessonActor={selectedLesson}
                    />
                );
            }
        }

        if (state.matches("gameOver")) {
            return (
                <MountFlickerAnimation>
                    <GameOverScreen onRestartClick={onRestartClick} />
                </MountFlickerAnimation>
            );
        }

        if (state.matches("gameComplete")) {
            return (
                <MountFlickerAnimation>
                    <GameCompleteScreen />
                </MountFlickerAnimation>
            );
        }

        return null;
    };

    return (
        <>
            {component()}
            <ScanLines />
        </>
    );
};
