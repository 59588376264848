import { path1CoordsList } from "../coords/path1CoordsList";
import { ItemSettingsType, DirectionType } from "../types";

export const RENDER_LOOP_SECONDS = 1;

export const FAST_BLINKING_ANIMATION_DURATION_SECONDS = 1.2;
export const SLOW_BLINKING_ANIMATION_DURATION_SECONDS = 1.7;

export const TIME_TO_EXTRACTION_STARTING_TIME_SECONDS = 12 * 60;

export const ALIEN_SPEED_FRAMES = 10;

export const SONAR_ANIMATION_DURATION_SECONDS =
    ALIEN_SPEED_FRAMES * RENDER_LOOP_SECONDS;

export const PATH_UNLOCK_CADENCE_FRAME_COUNT = 100;
export const ALIEN_SPAWN_CADENCE_FRAME_COUNT = 100;
export const ALIEN_SPAWN_AMOUNT_INCREASE = 1;

export const INTRO_SONAR_SETTINGS: ItemSettingsType = {
    coords: path1CoordsList[2],
};
export const INTRO_SENTRY_SETTINGS: ItemSettingsType = {
    coords: path1CoordsList[3],
    direction: DirectionType.Up,
};
