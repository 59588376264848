import { CoordsType } from "../types";
import { isEqual, intersectionWith } from "lodash";
import { playableCoordsList } from "../coords/playableCoordsList";

export const calcDirectionButtonCoords = (originCoords: CoordsType): CoordsType[] => {
    const [x, y] = originCoords;
    const buttonCoords = [
        [x, y - 1],
        [x + 1, y - 1],
        [x + 1, y],
        [x + 1, y + 1],
        [x, y + 1],
        [x - 1, y + 1],
        [x - 1, y],
        [x - 1, y - 1],
    ] as CoordsType[];

    return intersectionWith(playableCoordsList, buttonCoords, isEqual);
};
