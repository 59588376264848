import { attemptCompileConfigAnswer } from "./compileMachine";
import {
    doesMachineHaveInitialState,
    doesMachineHaveStatePath,
    TransitionType,
    StatePathType,
    doesMachineHaveTransition,
} from "./machineQueries";

export const doesAnswerHaveInitialState = ({
    answer,
    initialStateKey,
}: {
    answer: string;
    initialStateKey: string;
}) => {
    const machine = attemptCompileConfigAnswer(answer);

    if (machine) {
        return doesMachineHaveInitialState({ machine, initialStateKey });
    }

    return false;
};

export const doesAnswerHaveStatePath = ({ answer, statePath }: { answer: string; statePath: StatePathType }) => {
    const machine = attemptCompileConfigAnswer(answer);

    if (machine) {
        return doesMachineHaveStatePath({ machine, statePath });
    }

    return false;
};

export const doesAnswerHaveTransition = ({ answer, transition }: { answer: string; transition: TransitionType }) => {
    const machine = attemptCompileConfigAnswer(answer);

    if (machine) {
        return doesMachineHaveTransition({ machine, transition });
    }

    return false;
};
