import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { CoordsType } from "../../types";
import { coordsToLeftTopPosition, MAP_SQUARE_DIMENSION } from "../../coords";

const Layout = styled.div<{ color?: string }>`
    width: ${MAP_SQUARE_DIMENSION}px;
    height: ${MAP_SQUARE_DIMENSION}px;

    position: absolute;

    border: 1px solid ${(p) => p.color || `hsla(0, 100%, 100%, 0.2)`};
`;

interface PropsType {
    color?: string;
    coords: CoordsType;
    children?: ReactNode;
}

export const CoordsSquare: FC<PropsType> = ({ children, color, coords }) => {
    const [left, top] = coordsToLeftTopPosition(coords);

    return (
        <Layout color={color} style={{ left: `${left}px`, top: `${top}px` }}>
            {children}
        </Layout>
    );
};
