import React, { FC } from "react";
import styled from "styled-components";
import {
    MAP_HEIGHT,
    MAP_WIDTH,
    // mapCoordsList
} from "../../coords";
// import { CoordsSquare } from "./CoordsSquare";
import { MapImage } from "./MapImage";
import { MountFlickerAnimation } from "../Animations/MountFlickerAnimation";

const Layout = styled.section`
    width: ${MAP_WIDTH}px;
    height: ${MAP_HEIGHT}px;

    position: absolute;
    top: 0;
    left: 0;
`;

// const MapSquares = () => (
//     <>
//         {mapCoordsList.map((coords) => (
//             <CoordsSquare key={coords.toString()} coords={coords} />
//         ))}
//     </>
// );

// const MapDots = () => (
//     <>
//         {mapCoordsList.map((coords) => (
//             <CoordsDot key={coords.toString()} coords={coords} />
//         ))}
//     </>
// );

export const Map: FC = ({ children }) => (
    <MountFlickerAnimation>
        <Layout>
            <MapImage />
            {/* <MapSquares /> */}
            {/* <MapDots /> */}
            {children}
        </Layout>
    </MountFlickerAnimation>
);
