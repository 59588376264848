import React, { FC } from "react";
import { useService } from "@xstate/react";
import { Timer } from ".";
import { ExtractionActorType } from "../../machines/extractionMachine/types";

interface PropsType {
    actor: ExtractionActorType;
}

export const TimerWithActor: FC<PropsType> = ({ actor }) => {
    // @ts-ignore
    const [state] = useService(actor);
    // @ts-ignore
    const { timeToExtractionSeconds } = state.context;

    return state.matches("inactive") ? null : <Timer seconds={timeToExtractionSeconds} />;
};
