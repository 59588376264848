import styled from "styled-components";
import { MAP_WIDTH, MAP_HEIGHT } from "../../coords";

export const CenterMap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    background: ${(p) => p.theme.color.fogOfWar};
`;

export const RelativeLayout = styled.div`
    width: ${MAP_WIDTH}px;
    height: ${MAP_HEIGHT}px;

    position: relative;
`;
