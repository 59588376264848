import React, { FC } from "react";
import styled, { css } from "styled-components";
import { MAP_WIDTH } from "../../coords";
import map from "../../images/map.svg";

export const Img = styled.img`
    ${({ theme }) => css`
        width: ${MAP_WIDTH}px;

        position: absolute;
        top: 0;
        left: 0;

        pointer-events: none;
    `}
`;

export const MapImage: FC = () => (
    <Img src={map} alt="Schematic of a facility." />
);
