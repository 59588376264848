import React, { FC, ReactNode } from "react";
import styled, { css } from "styled-components";

interface StyledParagraphPropsType {
    isMaxWidth: boolean;
}

export const paragraphStyles = css`
    width: 100%;
    max-width: ${(p) => {
        // @ts-ignore
        return p.isMaxWidth ? p.theme.maxWidth.paragraph : null;
    }};

    font-size: ${(p) => p.theme.fontSize[1]};

    /* 2. move text to sit on baseline */
    transform: translateY(0.35em);
    /* 3. space between multi-lines */
    line-height: 1.55;
    display: block;

    &::before {
        content: "";
        display: block;
        height: 0;
        /* 1. adjust margin-top to adjust the height of the outer element (it should be high enough to cover the top of an ascender and none of the decender below the baseline */
        margin-top: -12px;
    }

    a {
        color: ${(p) => p.theme.color.clickable};
        text-decoration: underline;
    }
`;

const StyledParagraph = styled.p<StyledParagraphPropsType>`
    ${paragraphStyles}
`;

interface PropsType {
    children: ReactNode;
    isMaxWidth?: boolean;
}

export const Paragraph: FC<PropsType> = ({ children, isMaxWidth = true }) => (
    <StyledParagraph isMaxWidth={isMaxWidth}>{children}</StyledParagraph>
);
